// FILTERS
// import React from 'react'
import { isNil } from 'lodash'
// Define a default Aggregate cell
// function DefaultAggregate({
//   column: { }
// }) {
//   return (
//     <span> </span>
//   )
// }

//Function(leafValues, aggregatedValues) => any
const nilCount = (values) => {
  return values.filter(isNil).length
}

const aggregateTypes = {
  nilCount
}

const defaultTableGroupByOptions = {
  // initialState: {
    // groupBy: [id: '_accessor']
  // },
  manualGroupBy: false,
  // disableGroupBy: true
}

const defaultColumnGroupByOptions = {
  //Aggregated: Function | React.Component => JSX
  //aggregate: String | Function(leafValues, aggregatedValues) => any
  //aggregateValue: String | Function(values, row, column) => any
  disableGroupBy: false
}

export {
  aggregateTypes,
  defaultTableGroupByOptions,
  defaultColumnGroupByOptions
}