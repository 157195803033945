import purposeConfig from './purposeConfig'
import strategyConfig from './strategyConfig'
import tacticConfig from './tacticConfig'
import projectConfig from './projectConfig'
import portfolioConfig from './portfolioConfig'
import projectEventConfig from './projectEventConfig'
import performanceAttributeConfig from './performanceAttributeConfig'
import performanceMetricConfig from './performanceMetricConfig'
import performanceMetricBatchConfig from './performanceMetricBatchConfig'



export default {
  purpose: purposeConfig,
  strategy: strategyConfig,
  tactic: tacticConfig,
  project: projectConfig,
  portfolio: portfolioConfig,
  projectEvent: projectEventConfig,
  performanceAttribute: performanceAttributeConfig,
  performanceMetric: performanceMetricConfig,
  performanceMetricBatch: performanceMetricBatchConfig
}