
// Sort Types
// columnId = "accessor"
// const sortMethod = (rowA, rowB, columnId, desc) => {
//   return rows.sort(blah)
// }

const dateString = (rowA, rowB, columnId) => {
  const date1 = new Date(rowA.original[columnId])
  const date2 = new Date(rowB.original[columnId])
  if(date1.getTime() > date2.getTime()){
    return 1
  }
  return -1
}

const sortTypes = {
  dateString
}

const defaultTableSortByOptions = {
  // initialState: {
    // sortBy: [{ id: columnId, desc: Bool }]
  // },
  manualSortBy: false,
  disableSortBy: false,
  defaultCanSort: false,
  disableMultiSort: false,
  isMultiSortEvent: undefined, // default is when shift key is held
  maxMultiSortColCount: undefined,
  disableSortRemove: false,
  disableMultiRemove: false,
  orderByFn: undefined, // default is probably fine
  autoResetSortBy: false
}

const defaultColumnSortByOptions = {
  defaultCanSort: false,
  disableSortBy: false,
  sortDescFirst: false,
  sortInverted: false,
  sortType: 'alphanumeric' // String options: basic, datetime, alphanumeric. Defaults to alphanumeric
}

//default filter is "text"
export {
  sortTypes,
  defaultTableSortByOptions,
  defaultColumnSortByOptions
}