import React, { useState, useEffect } from 'react'
import { sortBy, groupBy } from 'lodash'
import moment from 'moment'

function MetricsView(props) {
  const [metricData, setMetricData] = useState([])

  useEffect(() => {
    if (!props.metrics || !props.attributeMap) {
      return
    }

    const filteredMetrics = props.metrics.filter(metric => new Date(metric.recordedDate) <= new Date())
    const sortedMetrics = sortBy(filteredMetrics, (metric) => new Date(metric.recordedDate).getTime())
    const sortedMetricsByAttribute = groupBy(sortedMetrics, 'performanceAttributeId')
    const metricData = Object.values(sortedMetricsByAttribute).map((attributeMetrics) => {
      const latestRecordedMetric = attributeMetrics.pop() || {}
      const { performanceAttributeId, value, recordedDate } = latestRecordedMetric
      const { name: attributeName, unit: attributeUnit } = props.attributeMap[performanceAttributeId]
      return {
        name: attributeName,
        unit: attributeUnit,
        value,
        date: recordedDate
      }
    })

    setMetricData(sortBy(metricData, 'name'))
  }, [props.metrics, props.attributeMap])

  return (
    <div>
      <h4 style={{ margin: "0 0 10px" }}>Project Metrics</h4>
      <div style={{ overflowY: "scroll", maxHeight: "calc(40vh - 78px)", borderTop: "1px solid var(--project-color)" }}>
        {
          metricData.map(metricDatum => {
            const { name, unit, value, date } = metricDatum
            return (
              <div key={name} style={{ display: "flex", border: "1px solid var(--project-color)", borderTop: "none" }}>
                <div style={{ flexBasis: "45%", padding: "2px 4px", borderRight: "1px solid var(--project-color)" }}>
                  {name}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "35%", padding: "2px 4px", borderRight: "1px solid var(--project-color)" }}>
                  <span>{value.toLocaleString()}</span><span>{unit}</span>
                </div>
                <div style={{ flexBasis: "20%", padding: "2px 4px", textAlign: "center" }}>
                  {moment(date).format('MM/DD/YY')}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default MetricsView