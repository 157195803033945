import React, { useCallback } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import dataService from '../../services/dataService'

function DeleteWarningDialog({ closeHandler, resourceType, activityId }) {

  const deleteHandler = useCallback(async () => {
    await dataService.delete(`/${resourceType}/${activityId}`)
    closeHandler()
  }, [resourceType, activityId, closeHandler])

  return (
    <Dialog 
      open={true}
    >
      <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this? 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="primary" autoFocus>
          No
        </Button>
        <Button 
          onClick={(e) => {
            e.stopPropagation()
            deleteHandler()
          }} 
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteWarningDialog