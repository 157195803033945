import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import colorMap from './resourceColorMap'

function OtherOtherSearchResult ({ searchResult, selected, inputValue, setSelectedSearchResult }) {
  if (!searchResult) return <div>none</div>
  return (
    <ListItem button className='search-result-item' onClick={()=>setSelectedSearchResult(searchResult)}>
      <ListItemIcon className="search-result-item-icon-container" style={{ ...(colorMap[searchResult.resourceType] ? colorMap[searchResult.resourceType] : {}) }}>
        {searchResult.resourceType.slice(0, 2)}
      </ListItemIcon>
      <ListItemText className="search-result-item-text">
        {searchResult.resourceName}
      </ListItemText>
    </ListItem>
  )
}

export default OtherOtherSearchResult
