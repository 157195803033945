import React from 'react';
import { TextField } from '@material-ui/core';

const columns = [
  {
    accessor: 'name',
    Header: 'NAME',
    disableGroupBy: true,
    Cell: ({ value, row: { index }, column, row, updateTableData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (
        isEditable ?
          <TextField
            key={column.id + row.id}
            variant="outlined"
            value={value || ""}
            onChange={(e) => updateTableData(index, "name", e.target.value)}
          />
        :
        <p>{value || ""}</p>
      )
    }
  }
]


export default columns