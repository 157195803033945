import React, { useState, useEffect } from 'react'
import { Button, TextField, Tabs, Tab, Divider, Box, makeStyles, Dialog, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core'
import dataService from '../../../services/dataService'
import jsFileDownload from 'js-file-download'
import CollaborationPanel from '../CollaborationPanel'
import FeatureToggleList from '../FeatureToggleList'

import { observer } from 'mobx-react-lite'
import { strategyStore } from '../../../stores'

const DataTable = observer(() => {
    const [data, setData] = useState({})
    const [tabValue, setTabValue] = useState(0)
    const [resetDataOpen, setResetDataOpen] = useState(false)
    const [fileImportMessage, setFileImportMessage] = useState(null)

    const { dataTimestamp, dataSetName } = strategyStore

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width: '100%'}}
          >
            {value === index && (
              <Box p={3} style={{width: '100%'}}>
                <div>{children}</div>
              </Box>
            )}
          </div>
        );
    }

    const fetchData = async () => {
        setData(dataService.getRepo())
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            display: 'flex'
        },
        sidebar: {
            minWidth: '250px',
            borderRight: '.1px solid #979797'
        }
    }))

    const classes = useStyles()

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        }
    }

    useEffect(() => {
        fetchData()
    }, [dataTimestamp, dataSetName])

    const dataUploadHandler = async (e) => {
        setFileImportMessage("Loading file")
        const file = e.target.files[0]
        if(file) {
            const fileReader = new FileReader()
            fileReader.onloadend = (e) => { 
                try {
                    const data = JSON.parse(fileReader.result)
                    dataService.updateStores(data)
                    fetchData()
                    setFileImportMessage("File loaded successfully")
                } catch (err) {
                    setFileImportMessage("Failed to load file: ", err.message)
                }
            }
            fileReader.readAsText(file)
        }
    }

    return(
        <div className={classes.root}>
            <div className={classes.sidebar}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs example"
                    // className={classes.tabs}
                >
                    <Tab label="Collaboration" {...a11yProps(0)} />
                    <Tab label="Import Data" {...a11yProps(1)} />
                    <Tab label="Current Dataset" {...a11yProps(2)} />
                    <Tab label="Clear Dataset" {...a11yProps(3)} />
                    <Tab label="Experimental Features" {...a11yProps(4)} />
                </Tabs>
            </div>

            <TabPanel value={tabValue} index={0}>
                <CollaborationPanel />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <TextField type="file" name="file" onChange={dataUploadHandler} label="Upload Data" />
                    {fileImportMessage && <div>{fileImportMessage}</div>}
                </div>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <Button onClick={e => jsFileDownload(JSON.stringify(data, null, 2), data.repoFileName || 'bassData.json')}>Export</Button>
                <Divider/>
                <pre>{JSON.stringify(data, ' ', 2)}</pre>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <Dialog
                    open={resetDataOpen}
                    onClose={() => { setResetDataOpen(false) }}
                    aria-labelledby="reset-data-dialog"
                    aria-describedby="reset-data-dialog-description"
                >
                    <DialogTitle>Reset Repository</DialogTitle>
                    <DialogContentText>Are you sure you want to reset all your data?</DialogContentText>
                    <DialogActions>
                        <Button onClick={() => { 
                            dataService.resetRepoToClean() 
                            setResetDataOpen(false)
                        }}>Clear All Data</Button>
                        <Button onClick={() => { 
                            dataService.resetRepoToDefault() 
                            setResetDataOpen(false)
                        }}>Reset Default Data</Button>
                        <Button onClick={() => { setResetDataOpen(false) }} >Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Button onClick={e => {setResetDataOpen(true)}} >Reset Repository</Button>
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
                <FeatureToggleList />
            </TabPanel>

        </div>
    )
})

export default DataTable