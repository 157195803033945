import { strategyStore } from '../stores'
import { gunzip, gzip } from 'zlib'
import { promisify } from 'util'
import dataService from './localDataStore'

const gzipP = promisify(gzip)
const gunzipP = promisify(gunzip)

const AWS = require("aws-sdk")

AWS.config.update({
  region: "us-west-2",
  // endpoint: "http://localhost:8000",
  endpoint: "https://dynamodb.us-west-2.amazonaws.com",
  dynamoDbCrc32: false,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY 
})

const bassCatalogSchema = {
  TableName: "BASS_Catalog",
  KeySchema: [
    { AttributeName: "Data_Set_Name", KeyType: "HASH"}
  ],
  AttributeDefinitions: [
    { AttributeName: "Data_Set_Name", AttributeType: "S"},
  ],
  ProvisionedThroughput: {
    ReadCapacityUnits: 10, 
    WriteCapacityUnits: 10
  }
}

const bassDataTableSchema = {
  TableName: "BASS_Data",
  KeySchema: [
    { AttributeName: "Data_Set_Name", KeyType: "HASH"}
  ],
  AttributeDefinitions: [
    { AttributeName: "Data_Set_Name", AttributeType: "S"},
  ],
  ProvisionedThroughput: {
    ReadCapacityUnits: 10, 
    WriteCapacityUnits: 10
  }
}

const DynDB = new AWS.DynamoDB()

export async function getServiceStatus() {
  const { status, failure, tables } = strategyStore.collaborationData
  return {
    status,
    failure,
    tables
  }
}

export async function updateLocalFromRepoString(repoString) {
  const { dataSetName, dataFileTimestamp } = strategyStore
  const buf = new Buffer.from(repoString, 'base64')
  const uncompressedBuf = await gunzipP(buf)
  const jsonString = uncompressedBuf.toString()
  const newRepo = JSON.parse(jsonString)
  if (newRepo.dataSetName === dataSetName && newRepo.dataTimestamp > dataFileTimestamp) {
    dataService.updateStores(newRepo)
  }
}

export async function update() {
  const { collaborationData, dataSetName, dataFileTimestamp } = strategyStore
  const currentTimestamp = Date.now()
  if (dataSetName) {
    const catalogResponse = await DynDB.getItem({
      TableName: bassCatalogSchema.TableName,
      Key: { Data_Set_Name: { S: dataSetName } }
    }).promise()
    if(!catalogResponse.Item) {
      strategyStore.setCollaborationData({
        ...collaborationData,
        lastServerCheck: currentTimestamp,
        lastServerStatus: 'Failed',
        lastServerError: 'Data Set not found on server'
      })
      return
    }
    const ts = Number.parseInt(catalogResponse.Item.Data_Set_Timestamp.N, 10)
    strategyStore.setCollaborationData({ ...collaborationData, lastServerCheck: currentTimestamp })
    if (ts > dataFileTimestamp) {
      strategyStore.setCollaborationData({ 
        ...collaborationData, 
        lastServerCheck: currentTimestamp,
        lastServerStatus: 'Updating',
        lastServerError: null
      })
      try {
        const itemResponse = await DynDB.getItem({
          TableName: bassDataTableSchema.TableName,
          Key: { Data_Set_Name: { S: dataSetName } }
        }).promise()
        await updateLocalFromRepoString(itemResponse.Item.Data_Set.S)
      } catch (err) {
        strategyStore.setCollaborationData({
          ...collaborationData,
          lastServerCheck: currentTimestamp,
          lastServerStatus: 'Failed',
          lastServerError: err.message
        })
      }
    } else if (ts < dataFileTimestamp) {
      strategyStore.setCollaborationData({ 
        ...collaborationData, 
        lastServerCheck: currentTimestamp,
        lastServerStatus: 'Conflict'
      })
    } else {
      strategyStore.setCollaborationData({
        ...collaborationData,
        lastServerCheck: currentTimestamp,
        lastServerStatus: 'OK'
      })
    }
  }
  setTimeout(() => {
    update()
  }, 10000)
}

export async function initialize() {
  strategyStore.setCollaborationData({ ...strategyStore.collaborationData, status: 'Initializing Service' })
  try {
    const tableList = await DynDB.listTables().promise()
    const tables = tableList.TableNames.map(p => p)

    if (tables.indexOf(bassCatalogSchema.TableName) < 0) {
      const data = { ...strategyStore.collaborationData, status: 'Initializing BASS', tables }
      strategyStore.setCollaborationData(data)

      await DynDB.createTable(bassCatalogSchema).promise()
      await DynDB.createTable(bassDataTableSchema).promise()
      setTimeout(() => { initialize() }, 60000)
      return
    } else {
      const data = { ...strategyStore.collaborationData, status: 'Online', tables }
      delete data.failure
      strategyStore.setCollaborationData(data)
      update()
    }
  } catch (err) {
    console.log("GOT ERROR: ", err)
    const data = {...strategyStore.collaborationData, status: 'Connection Failed', failure: err.message}
    strategyStore.setCollaborationData(data)
    setTimeout(() => { initialize() }, 60000)
  }
}

function generateAccessKey(len = 10) {
  let key = ''
  while(key.length < len) {
    key += Math.random().toString(36).substr(2, 10);
  }
  return key.substr(0,len)
}

async function updateCatalog(repo) {
  const { dataSetName, dataTimestamp }  = repo

  const repoString = JSON.stringify(repo)
  const compressedRepo = await gzipP(repoString)
  const repoStoreString = compressedRepo.toString('base64')

  await DynDB.putItem({
    TableName: bassCatalogSchema.TableName,
    Item: {
      Data_Set_Name: { S: dataSetName },
      Data_Set_Size: { N: `${repoStoreString.length}` },
      Data_Set_Timestamp: { N: `${dataTimestamp}` }
    }
  }).promise()

  await DynDB.putItem({
    TableName: bassDataTableSchema.TableName,
    Item: {
      Data_Set_Name: { S: dataSetName },
      Data_Set: {S: `${repoStoreString}`},
      Data_Set_Timestamp: { N: `${dataTimestamp}` }
    }
  }).promise()
}

export const enableCollaboration = async (name, repo) => {
  const accessKey = generateAccessKey()

  const checkResponse = await DynDB.getItem({
    TableName: bassCatalogSchema.TableName,
    Key: { Data_Set_Name: { S: name } }
  }).promise()

  if (checkResponse.Item) {
    throw new Error("A dataset with this name already exists online!")
  }

  dataService.updateCollaborationData({
    dataSetName: name,
    dataSetAccessKey: accessKey
  })

  await updateCatalog(dataService.getRepo())

  return 
}

export const collaborationDataService = {
  getStatus: getServiceStatus,
  enableCollaboration,
  updateCatalog,
  initialize
}
