import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@material-ui/core';
import ReactQuill from 'react-quill'
import dataService from '../../services/dataService'
import { capitalize, clone } from 'lodash';
import { toJS } from 'mobx'

const colorClassMapping = {
  'STRATEGIES': 'strategy',
  'TACTICS': 'tactic',
  'PROJECTS': 'project'
}

const parentIdKeyMapping = {
  'STRATEGIES': 'purposeId',
  'TACTICS': 'strategyId',
  'PROJECTS': 'tacticId'
}

const colorObject = {
  strategy: '#EC8361',
  tactic: '#53AFF6',
  project: '#67C57E'
}

function EditDialog({closeHandler, type, activity, parentId}) {

  const [newName, setNewName] = useState(activity ? activity.name : '')
  const [newDescription, setNewDescription] = useState(activity ? activity.description : '')

  const handleSave = async (type)=>  {
    try{
    if (activity) {
        const updatedActivity = clone(toJS(activity))
        updatedActivity.name = newName
        updatedActivity.description = newDescription
        await dataService.put(`/${type.toLowerCase()}/${updatedActivity.id}`, updatedActivity)
      } else {
        const newActivity = { 
          name: newName,
          description: newDescription,
          [parentIdKeyMapping[type]]: parentId
        }
        await dataService.post(`/${type.toLowerCase()}`, newActivity)
      }
      closeHandler()
    } catch (err){
      console.log("Unable to save: ", err)
    }
  }

  const updateDescription = (content) => {
    setNewDescription(content)
  }

  const classStyle = {
    root: `edit-dialog-background`
  }

  return (
    <Dialog 
      open={true} 
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle 
        id='form-dialog-title'  
        className={`${colorClassMapping[type]}-color ${colorClassMapping[type]}-background-color ${colorClassMapping[type]}-border-color edit-dialog-background`}
      >
        { activity ? 
        `Modify ${activity.name}` :
        `Create New ${capitalize(colorClassMapping[type])}`
      }
      </DialogTitle>
      <DialogContent 
        className={classStyle.root}
      >
        <FormControl
        >
          <InputLabel 
            style={{color: `${colorObject[colorClassMapping[type]]}`}}
          >
            {capitalize(type.toLowerCase())} Name
          </InputLabel>
          <Input 
            style={{color: `${colorObject[colorClassMapping[type]]}`}}
            name={`modify-${colorClassMapping[type]}-modal-input`}
            aria-label={`${colorClassMapping[type]}-name-input`}
            value={newName}
            onChange={(e) => {setNewName(e.target.value)}} 
          />
        </FormControl>
          <ReactQuill
            bounds=".editable-table"
            theme="snow"
            value={newDescription}
            onChange={(content) => updateDescription(content)}
            placeholder="Add a Description..."
            preserveWhitespace
            style={{color: `${colorObject[colorClassMapping[type]]}`}} 
          />
      </DialogContent>
      <DialogActions 
        style={{backgroundColor: '#1a1b1f'}}
      >
        <Button 
          style={{color: `${colorObject[colorClassMapping[type]]}`}} 
          onClick={() => handleSave(type)}
          disabled={!newName}
        >
          { activity ? 'SAVE' : 'CREATE' }
        </Button>
        <Button 
          style={{color: `${colorObject[colorClassMapping[type]]}`}} 
          onClick={closeHandler}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog