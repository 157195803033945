import { Button, Input, Modal } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import dataService from '../../services/dataService'
import ReactQuill from 'react-quill'
import { strategyStore } from '../../stores'
import './ContentHeader.css'
import { observer } from 'mobx-react-lite'
import {toJS} from 'mobx'
import { useDrop } from 'react-dnd';
import { clone } from 'lodash';

const ContentHeader = observer(({goalId, onClick, style, shown, selected}) =>{
  const [showEditModal, setShowEditModal] = useState(false)
  const [newName, setNewName] = useState(null)
  const [newDescription, setNewDescription] = useState(null)
  const [goal, setGoal] = useState({})

  const { dataFileTimestamp } = strategyStore

  useEffect(() =>{
    const g = strategyStore.goals.find(g => g.id === goalId)
    setGoal(g)
  }, [goalId, dataFileTimestamp])

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "STRATEGIES",
    drop: async (item) => { 
      const record = toJS(strategyStore[item.type.toLowerCase()].find(n => n.id === item.id))
      if(record.purposeId === item.parentId) {
        record.purposeId = goalId
      }
      await dataService.put(`/${item.type.toLowerCase()}/${record.id}`, record)
    },
    canDrop: (item, monitor) => { 
      return goalId !== item.parentId
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  })

  const  handleSave = async ()=>  {
    try{
      const updatedGoal = clone(toJS(goal))
      updatedGoal.name = newName
      updatedGoal.description = newDescription
      await dataService.put(`/goals/${goal.id}`, updatedGoal)
      setShowEditModal(false)
    } catch (err){
      console.log("Unable to save: ", err)
    }
  }

  const updateDescription = (content) => {
    setNewDescription(content)
  }

  return(
    <div
      className={`
        content-header 
        ${shown ? '' : 'content-header-background '}
        ${canDrop ?'valid-drop-target ' : ''} 
        ${isOver && canDrop ? 'hovering-over-drop-target ' : ''}
      `} 
      onClick={onClick} 
      style={style}
      ref={drop}
      >
      {goal.name}
      {selected &&
        <div style={{'margin': '5px 0 0 5px'}}>
          <Edit className='goal-edit-icon'
            onClick={() => { setShowEditModal(true) 
            setNewName(goal.name)
            setNewDescription(goal.description)
          }}/>
        </div>
      }
      <Modal open={showEditModal} onClose={() => { setShowEditModal(false) }}>
        <div className='header-edit-modal'>
          <h2>Modify goal: {goal.name}</h2>
          <div>
            <b>Goal Name</b>: <Input name="modify-goal-modal-input" aria-label="goal-name-input" 
            value={newName}
            onChange={(e) => {setNewName(e.target.value)}} />
          </div>
          <ReactQuill
            bounds=".editable-table"
            theme="snow"
            value={newDescription}
            onChange={(content) => updateDescription(content)}
            placeholder="Add a Description..."
            preserveWhitespace
          />
          <div>
            <Button onClick={() => handleSave()}>SAVE</Button>
            <Button onClick={() => { setShowEditModal(false) }}>CANCEL</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
})

export default ContentHeader