import React from 'react';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextField } from '@material-ui/core';

const columns = [
  {
    accessor: 'name',
    Header: 'NAME',
    disableGroupBy: true,
    Cell: ({ value, row: { index }, column, row, updateTableData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (
        isEditable ? <TextField
          variant="outlined"
          value={value || ""}
          onChange={(e) => updateTableData(index, "name", e.target.value)}
        /> : <p>{value}</p>
      )
    }
  },
  {
    accessor: 'description',
    Header: 'DESCRIPTION',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows}) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return isEditable ?
        <ReactQuill
          bounds=".editable-table"
          theme="snow"
          value={value}
          onChange={(content, delta, source, editor) => updateTableData(index, 'description', content)}
          placeholder="Add a Description..."
          preserveWhitespace
        />
        : <div className="description-cell">{value}</div>
    }
  }
]


export default columns