import React from 'react'
import { ListSubheader } from '@material-ui/core'
import colorMap from './resourceColorMap'

function OtherOtherSearchGroup ({ group, children }) {
  return (
    <li className="search-result-section">
      <ul className="search-result-group">
        <ListSubheader
          component="li"
          className="search-result-group-header"
          style={{ ...(colorMap[group] ? colorMap[group] : {}) }}
        >
          {group}
        </ListSubheader>
        {children}
      </ul>
    </li>
  )
}

export default OtherOtherSearchGroup
