import React from 'react'
import SearchBox from '../search/OtherOtherSearchBox'
import UserBadge from '../UserBadge'
import FishPhoto from '../../icons/BASS-icon.png'
import './Header.css'

import { observer } from 'mobx-react-lite'
import { strategyStore } from '../../stores'

const Header = observer(({setSearchResult}) => {

  const { dataSetName } = strategyStore

  return (
    <div id="header">
      <SearchBox setSelectedSearchResult={setSearchResult}/>
      <div>
        <UserBadge name="Collinear Group" role={dataSetName ? dataSetName : 'Local Data'} userImage={FishPhoto} />
      </div>
    </div>
  )
})

export default Header