import React from 'react';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';

const columns = [
  {
    accessor: 'name',
    Header: 'NAME',
    disableGroupBy: true,
    Cell: ({ value, row: { index }, column, row, updateTableData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (isEditable ?
        <TextField
          key={column.id + row.id}
          variant="outlined"
          value={value || ""}
          onChange={(e) => updateTableData(index, "name", e.target.value)}
        />
        : <p>{value || ""}</p>)
    }
  },
  {
    accessor: 'tacticId',
    Header: 'TACTIC',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows}) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { tactics = {} } = allData
      const tactic = tactics[value]
      return (isEditable ?
        <Autocomplete
          options={Object.values(tactics)}
          getOptionLabel={(tactic) => tactic.name || ""}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'tacticId', newVal?.id)
          }}
          value={tactic || null}
          renderInput={(params) => {
            return <TextField
              label="Select Tactic"
              variant="outlined"
              {...params}
              value={params.value || ''}
            />
          }}
        />
        : <p>{tactic?.name || ""}</p>)
    }
  },
  {
    accessor: 'description',
    Header: 'DESCRIPTION',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows}) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return isEditable ?
        <ReactQuill
          bounds=".editable-table"
          theme="snow"
          value={value}
          onChange={(content, delta, source, editor) => updateTableData(index, 'description', content)}
          placeholder="Add a Description..."
          preserveWhitespace
        />
        : <div className="description-cell">{value}</div>
    }
  }
]


export default columns