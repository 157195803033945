// FILTERS
import React from 'react'
import { TextField } from '@material-ui/core'
// Define a default UI for filtering
function DefaultFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  return (
    <TextField
      value={filterValue || ''}
      variant='outlined'
      size='small'
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  )
}

// FILTER TYPES
// id = "accessor"
const includesString = (rows, id, filterValue) => {
  return rows.filter(row => {
    const isNew = !(row.original?.id || row.original?.existing)
    if (isNew) {
      return true
    }

    const rowValue = row.values[id]
    return rowValue !== undefined
      ? String(rowValue)
        .toLowerCase()
        .indexOf(String(filterValue).toLowerCase()) > -1
      : true
  })
}



const filterTypes = {
  includesString
}

const defaultTableFilterOptions = {
  // initialState: {
    // filters: [{ id: '_accessor', value: 'filter value (any)'}]
  // },
  manualFilters: false,
  disableFilters: false,
  defaultCanFilter: false,
  autoResetFilters: false
}

const defaultColumnFilterOptions = {
  Filter: DefaultFilter,
  disableFilters: false,
  defaultCanFilter: false,
  filter: 'includesString'
}

//default filter is "text"
export {
  filterTypes,
  defaultTableFilterOptions,
  defaultColumnFilterOptions
}