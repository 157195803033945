import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField } from '@material-ui/core'
import { differenceWith, isEqual, valuesIn, compact, cloneDeep, keyBy, groupBy } from 'lodash'
import dataService from '../../services/dataService'
import tableConfigs from './tables/tableConfigs'
import { Delete } from '@material-ui/icons'
import EditableTable from './tables/EditableTable'

import { observer } from 'mobx-react-lite'
import { strategyStore } from '../../stores'

const ManagePerformanceMetrics = observer(() => {
  const resourcePath = 'performanceMetrics'
  const configColumns = tableConfigs.performanceMetricBatch
  const [tableData, setTableData] = useState(null)
  const [originalData, setOriginalData] = useState(null)
  const [changedRows, setChangedRows] = useState([])
  const [allData, setAllData] = useState({})
  const [editableRows, setEditableRows] = useState([])

  const buildMetricsData = useCallback((rawMetricData, performanceAttributes) => {
    const groupedMetrics = groupBy(rawMetricData, (metric) => {
      return `${metric.portfolioId}_${metric.projectId}_${metric.recordedDate}`
    })
    const returnVal = Object.values(groupedMetrics).map(metrics => {
      const { projectId, portfolioId, recordedDate } = metrics[0]
      const keyedMetrics = keyBy(metrics, "performanceAttributeId")
      const returnRow = {
        existing: true,
        projectId,
        portfolioId,
        recordedDate,
        metrics: keyedMetrics
      }

      return returnRow
    })
    return returnVal
  }, [])

  const { performanceMetrics, performanceAttributes } = strategyStore

  const fetchData = useCallback(() => {
    const fetchData = (async () => {
      const metricsData = buildMetricsData(performanceMetrics, performanceAttributes)
      const allData = dataService.getDataFromStores()
      setTableData(metricsData)
      setOriginalData(metricsData)
      const keyedData = {}
      for (let dataType in allData) {
        keyedData[dataType] = keyBy(allData[dataType], 'id')
      }
      setAllData(keyedData)
    })
    fetchData()
  }, [buildMetricsData, performanceAttributes, performanceMetrics])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const addRow = useCallback(() => {
    setEditableRows(oldEditableRows => {
      return [...oldEditableRows, tableData.length.toString()]
    })
    setTableData((oldTableData) => {
      if (oldTableData) {
        return [...oldTableData, {}]
      }
      return [{}]
    })
  }, [tableData])

  const removeRow = useCallback((rowIndex) => {
    setEditableRows(oldEditableRows => {
      // eslint-disable-next-line
      return oldEditableRows.filter(row => row != rowIndex)
    })
    setTableData(oldTableData => {
      if (oldTableData) {
        const newTableData = cloneDeep(oldTableData)
        newTableData[rowIndex].deleted = !newTableData[rowIndex].deleted
        return newTableData
      }
      return oldTableData
    })
  }, [])

  useEffect(() => {
    setTableData(oldTableData => {
      const newTableData = cloneDeep(oldTableData)
      editableRows.forEach(row => {
        if (newTableData[row].deleted) {
          newTableData[row].deleted = false
        }
      })

      return newTableData
    })
  }, [editableRows])

  useEffect(() => {
    if (tableData && originalData) {
      setChangedRows(differenceWith(tableData, originalData, (val1, val2) => {
        const { deleted: d1, ...val1Data } = val1
        const { deleted: d2, ...val2Data } = val2
        return isEqual(val1Data, val2Data)
      }))
    }
  }, [tableData, originalData])

  const saveHandler = async () => {
    const saveResource = (async (resource) => {
      if (resource.id) {
        return dataService.put(`/${resourcePath}/${resource.id}`, resource)
      } else {
        return dataService.post(`/${resourcePath}`, resource)
      }
    })
    const deleteResource = (async (resource) => {
      if (resource.id) {
        return dataService.delete(`/${resourcePath}/${resource.id}`)
      }
    })
    const saveMetrics = (async ({ metrics = {}, portfolioId, projectId, recordedDate }) => {
      return Object.values(metrics).forEach(metric => {
        metric.portfolioId = portfolioId
        metric.projectId = projectId
        metric.recordedDate = recordedDate
        saveResource(metric)
      })
    })
    const deleteMetrics = (async ({ metrics = {}}) => {
      return Object.values(metrics).map(deleteResource)
    })

    const deletedRows = tableData.filter(row => row.deleted)
    const savePromises = changedRows
      .filter(row => compact(valuesIn(row)).length)
      .map(saveMetrics)
      .concat(deletedRows.map(deleteMetrics))

    await Promise.all(savePromises)
    fetchData()
    setEditableRows([])
  }

  const updateTableData = useCallback((rowIndex, property, newValue) => {
    setTableData(old => {
      return old.map((resource, index) => {
        // eslint-disable-next-line
        if (rowIndex == index) {
          return {
            ...resource,
            [property]: newValue,
          }
        }
        return resource
      })
    })
  }, [])

  const updateMetric = useCallback((rowIndex, attributeId, metricValue) => {
    setTableData(old => {
      return old.map((resource, index) => {
        // eslint-disable-next-line
        if (rowIndex == index) {
          return {
            ...resource,
            metrics: {
              ...resource.metrics,
              [attributeId]: {
                ...(resource.metrics?.[attributeId] || {}),
                value: metricValue,
                performanceAttributeId: attributeId,
              }
            }
          }
        }
        return resource
      })
    })
  }, [])

  const revertHandler = useCallback(() => {
    setTableData(originalData)
    setEditableRows([])
  }, [originalData])

  const tableColumns = useMemo(() => {
    const performanceAttributes = Object.values(allData?.performanceAttributes || {})
    return [
      {
        accessor: 'delete',
        Header: '',
        width: 15,
        disableFilters: true,
        disableSortBy: true,
        disableGroupBy: true,
        Cell: ({ row: { index } }) => {
          return <div onClick={(e) => {
            e.stopPropagation()
            removeRow(index)
          }}>
            <Delete />
          </div>
        }
      },
      ...configColumns.map(column => {
        const newColumn = cloneDeep(column)
        const originalCell = column.Cell
        if (originalCell) {
          newColumn.Cell = (...cellArgs) => {
            const [firstArg, ...otherArgs] = cellArgs
            return originalCell({ ...firstArg, updateTableData, allData, editableRows }, ...otherArgs)
          }
        }
        return newColumn
      }),
      ...performanceAttributes.map(attribute => {
        return {
          accessor: data => data.metrics?.[attribute.id]?.value,
          id: `${attribute.id}`,
          // accessor: attribute.name,
          Header: () => <><div>{attribute.name.toUpperCase()}</div><div>({attribute.unit})</div></>,
          Cell: ({ value, row: { index }, column, row }) => {
            const isEditable = editableRows.indexOf(row.id) >= 0
            return (isEditable ?
              <TextField
                type="number"
                label="Value"
                value={value || ""}
                variant="outlined"
                onChange={(e) => updateMetric(index, attribute.id, e.target.value)}
                id={`metric-value-${attribute.name.toLowerCase().replace(/\s/g, "-")}-${row.id}`}
              /> : <p>{value}</p>
            )
          }
        }
      })
    ]
  }, [removeRow, updateTableData, configColumns, allData, editableRows, updateMetric])

  return (
    !tableData ? null :
      <EditableTable
        data={tableData}
        allData={allData}
        columns={tableColumns}
        revertHandler={revertHandler}
        addRow={addRow}
        saveHandler={saveHandler}
        setEditableRows={setEditableRows}
        editableRows={editableRows}
      />
  )
})

export default ManagePerformanceMetrics;