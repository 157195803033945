import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { dataService, collaborationDataService } from './services'
import { strategyStore } from './stores'

const DataInitializer = observer(() =>{
    useEffect(() => {
        initializeStores()
        collaborationDataService.initialize()
    })
    return null
})

async function initializeStores(){
    if (!strategyStore.dataFileTimestamp) {
        await dataService.loadDefaultData()
    }
}

export default DataInitializer