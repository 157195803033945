import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { isEqual } from 'lodash'

import OtherOtherSearchResult from './OtherOtherSearchResult'
import OtherOtherSearchGroup from './OtherOtherSearchGroup'

import { observer } from 'mobx-react-lite'

import { strategyStore } from '../../stores'

import './OtherOtherSearch.css'

const Asynchronous = observer(({setSelectedSearchResult, event}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("")
  const [searchResults, setSearchResults] = useState(null);
  const loading = open && (!searchResults && searchInput)
  const prevSearchRequestRef = useRef()
  const prevSearchInputRef = useRef()
  const prevSearchRequest = prevSearchRequestRef ? prevSearchRequestRef.current : ""
  const prevSearchInput = prevSearchInputRef ? prevSearchInputRef.current : ""
  let searchPlaceholder = "Search";
  (event === true) ? searchPlaceholder = "Filter events" : searchPlaceholder = "Search"

  let className
  (event) ? className = 'event-filter' : className =""

  useEffect(() => {
    prevSearchInputRef.current = searchInput
  }, [searchInput])

  const sendSearchRequestFor = useCallback(async (searchValue, active) => {
    const searchMap = {
      Goal: strategyStore.goals,
      Strategy: strategyStore.strategies,
      Tactic: strategyStore.tactics,
      Project: strategyStore.projects
    }
    const searchResults = []
    Object.keys(searchMap).forEach(resourceType => {
      searchMap[resourceType].forEach(repoItem => {
        if (repoItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
          searchResults.push({ resourceType, resourceName: repoItem.name, resourceId: repoItem.id })
        }
      })
    })

    prevSearchRequestRef.current = searchValue

    if (active) {
      setSearchResults(searchResults);
    }
  }, [])

  useEffect(() => {
    let active = true;
    // console.group('Search useEffect')

    // No search has been made
    if (!searchResults && !searchInput) {
      // console.log("NO SEARCH BEING MADE")
      // console.groupEnd()
      return undefined
    }

    // Search box has been cleared after performing a search
    if ((searchResults && searchResults.length) && (prevSearchInput && !searchInput)) {
      // console.log("SEARCH BOX CLEARED AFTER HAVING SEARCHED")
      // console.groupEnd()
      prevSearchRequestRef.current = ""
      setSearchResults(null)
      return undefined
    }

    if (!searchInput) {
      // console.log("NO INPUT TO SEARCH FOR")
      // console.groupEnd()
      return undefined
    }

    // Search input results should already be in the current searchResults array
    if (prevSearchRequest && searchInput.toLowerCase().indexOf(prevSearchRequest.toLowerCase()) > -1) {
      // console.log("SEARCH INPUTS SHOULD BE IN PREVIOUS SEARCH OF", prevSearchRequest)
      // console.groupEnd()
      return undefined
    }

    sendSearchRequestFor(searchInput, active)
    // console.groupEnd()
    return () => { active = false }
  }, [searchInput, prevSearchInput, searchResults, prevSearchRequest, sendSearchRequestFor]);

  return (
    <Autocomplete
      id="other-other-search-box"
      className = {className}
      classes={{
        popper: 'other-other-search-popper'
      }}
      style={{ width: 300, backgroundColor: '#212027', borderRadius: '4px', border: '0.5px solid rgba(165, 165, 165, 0.5)' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={isEqual}
      includeInputInList
      noOptionsText="No Results Found"
      freeSolo
      loadingText={!searchInput ? "Enter Search" : "Searching..."}
      getOptionLabel={option => option.resourceName}
      groupBy={option => option.resourceType}
      onInputChange={(e, input, reason) => {
        if (reason === "input" || reason === "clear") {
          if (!input || input.length === 0) {
            setSelectedSearchResult(null)
          }
          setSearchInput(input)
        }
      }}
      inputValue={searchInput || ""}
      options={searchResults || []}
      loading={loading}
      ListboxProps={{ className: "search-results" }}
      renderInput={params => (
        <TextField
          {...params}
            label={searchPlaceholder}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderGroup={(groupProps) => {
        return <OtherOtherSearchGroup {...groupProps} />
      }}
      renderOption={(searchResult, optionProps) => {
        return <OtherOtherSearchResult searchResult={searchResult} {...optionProps} setSelectedSearchResult={(res) => {
          setSearchInput(res.resourceName || searchInput)
          setSelectedSearchResult(res)
        }}/>
      }}
    />
  );
})

export default Asynchronous
