import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Switch,
  Route,
} from "react-router-dom";
import { uniq, groupBy, sortBy, sortedUniq, uniqBy } from 'lodash'
import { Select, MenuItem } from '@material-ui/core'
import BubbleChart from '../charts/BubbleChart';

import { observer } from 'mobx-react-lite'

import { strategyStore } from '../../stores'

import './VisualizationContainer.css'
import TimeControl from './TimeControl';

const VisualizationContainer = observer((props) => {
  const [bubbleData, setBubbleData] = useState(null)
  const [sliderDate, setSliderDate] = useState(null)
  const [selectedAttributeId, setSelectedAttributeId] = useState(window.localStorage.getItem('BASS.SelectedAttributeID') || null)

  const { performanceAttributes, performanceMetrics } = strategyStore
  const { portfolios, projects, goals, strategies, tactics } = strategyStore

  const selectedAttribute = useMemo(() => {
    if (!performanceAttributes || !selectedAttributeId) {
      return null
    }
    // eslint-disable-next-line
    return performanceAttributes.find(attribute => attribute.id == selectedAttributeId)
  }, [selectedAttributeId, performanceAttributes])

  const filteredMetrics = useMemo(() => { 
    if(!performanceMetrics || !sliderDate) {
      return []
    }
    const dateFilteredMetrics = performanceMetrics.filter(metric => new Date(metric.recordedDate).getTime() <= sliderDate)
    return uniqBy(sortBy(dateFilteredMetrics, v => new Date(v.recordedDate).getTime()).reverse(), (metric) => {
      return `${metric.projectId}_${metric.portfolioId}_${metric.performanceAttributeId}`
    })
  }, [performanceMetrics, sliderDate])

  const sliderDates = useMemo( () => {
    if(!performanceMetrics || !selectedAttributeId) {
      return []
    }
    // eslint-disable-next-line
    const attributeMetrics = performanceMetrics.filter(metric => metric.performanceAttributeId == selectedAttributeId)
    const sortedUniqueDates = sortedUniq(
      sortBy(attributeMetrics.map(metric => new Date(metric.recordedDate).getTime()), (v) => v)
    )
    return sortedUniqueDates
  }, [performanceMetrics, selectedAttributeId])

  useEffect(() => {
    if (sliderDates.length === 0) {
      return
    }
    setSliderDate(sliderDates[sliderDates.length-1])
  }, [sliderDates, setSliderDate])

  useEffect(() => {
    const getPerformanceAttributes = async () => {
      if (performanceAttributes.length && (!selectedAttributeId || !performanceAttributes.find(({ id }) => id === selectedAttributeId))) {
        setSelectedAttributeId(performanceAttributes[0].id)
      }
    }
    getPerformanceAttributes()
  }, [performanceMetrics, performanceAttributes, selectedAttributeId])

  // format business metric data
  useEffect(() => {
    if (!selectedAttribute || !props.location.pathname.startsWith('/business')) {
      return
    }
    const businessData = { name: "Business: " + selectedAttribute.name }
    let hasData = false
    portfolios.forEach(portfolio => {
      const portfolioMetrics = filteredMetrics.filter(metric => metric.performanceAttributeId === selectedAttribute.id)
      const portfolioProjects = uniq(portfolioMetrics.map(metric => metric.projectId)).map(projectId => projects.find(p => p.id === projectId))
      const metricsByPortfolio = groupBy(portfolioMetrics, 'portfolioId')
      businessData.children = portfolios.map(portfolio => {
        const { name, id: portfolioId } = portfolio
        if (!metricsByPortfolio[portfolioId]) {
          return null
        }
        const metricsByProject = groupBy(metricsByPortfolio[portfolioId], 'projectId')
        const portfolioChildren = portfolioProjects.map(project => {
          const { name, id: projectId } = project
          if (!metricsByProject[projectId]) {
            return null
          }
          const projectChildren = metricsByProject[projectId].map(metric => {
            const { value, recordedDate } = metric
            if(Number.parseInt(value, 10)) {
              hasData = true
            }
            return { name: `${name} - ${value}${recordedDate ? `\n${recordedDate}` : ""}`, value: Number.parseInt(value, 10) }
          })

          return { name, children: projectChildren }
        }).filter(child => child)

        return { name, children: portfolioChildren }
      }).filter(child => child)
    })

    businessData.hasData = hasData
    setBubbleData(businessData)

  }, [setBubbleData, portfolios, projects, selectedAttribute, filteredMetrics, props.location.pathname])

  // format Alignment Data
  useEffect(() => {
    if (!props.location.pathname.startsWith('/alignment')) {
      return
    }
    const alignmentData = { name: "Alignment: " + (selectedAttribute ? selectedAttribute.name : "Funded Something") }
    let metricsByProject = {}
    let hasData = false
    if(selectedAttribute){
      // eslint-disable-next-line
      const attributeMetrics = filteredMetrics.filter(metric => metric.performanceAttributeId == selectedAttribute.id)
      const formattedMetrics = attributeMetrics.map(metric => {
        const { value, recordedDate } = metric
        const { name, id: projectId } = projects.find(p => p.id === metric.projectId)

        const numericVal = Number.parseInt(value, 10)

        if(numericVal) {
          hasData = true
        }
        
        return { name: `${name} - ${value}${recordedDate ? `\n${recordedDate}` : ""}`, value, projectId }
      })
      metricsByProject = groupBy(formattedMetrics, 'projectId')
    } 
    const formattedProjects = Object.keys(metricsByProject).map(projectId => {
      const { name, tacticId } = projects.find(p => p.id === projectId)
      return {
        name,
        tacticId,
        children: metricsByProject[projectId]
      }
    })
    const projectsByTactic = groupBy(formattedProjects, 'tacticId')
    const formattedTactics = Object.keys(projectsByTactic).map(tacticId => {
      const { name, strategyId } = tactics.find(t => t.id === tacticId)
      return {
        name,
        strategyId,
        children: projectsByTactic[tacticId]
      }
    })
    const tacticsByStrategy = groupBy(formattedTactics, 'strategyId')
    const formattedStrategies = Object.keys(tacticsByStrategy).map(strategyId => {
      const { name, purposeId } = strategies.find(s => s.id === strategyId)
      return {
        name, 
        purposeId,
        children: tacticsByStrategy[strategyId]
      }
    })
    const strategiesByPurpose = groupBy(formattedStrategies, 'purposeId')
    const formattedPurposes = Object.keys(strategiesByPurpose).map(purposeId => {
      const { name } = goals.find(g => g.id === purposeId)
      return {
        name,
        children: strategiesByPurpose[purposeId]
      }
    })
    alignmentData.children = formattedPurposes
    alignmentData.hasData = hasData
    setBubbleData(alignmentData)
  }, [selectedAttribute, goals, projects, tactics, strategies, filteredMetrics, props.location.pathname])

  useEffect(() => {
    window.localStorage.setItem('BASS.SelectedAttributeID', selectedAttributeId)
  }, [selectedAttributeId])

  let visMessage = ''
  if(window.location.pathname.indexOf('business')>0) {
    visMessage = 'Portfolios'
  } else if (window.location.pathname.indexOf('alignment')>0) {
    visMessage = 'Alignment'
  }
  if(!bubbleData || !bubbleData.children) {
    return <div></div>
  }

  return (
    <div id='visualization-container' style={{ position: "relative" }} >
      <div
        className="visualization-container-message"
      >{visMessage}</div>
      <Select
        style={{ position: "absolute", top: '10px', right: '10px', backgroundColor: "#fff", minWidth: "250px", textIndent: "10px" }}
        value={selectedAttributeId}
        variant='outlined'
        onChange={(e) => {
          setSelectedAttributeId(e.target.value)
        }}
      >
        {performanceAttributes.map(attribute => (
          <MenuItem key={attribute.name} value={attribute.id}>{`${attribute.name} (${attribute.unit})`}</MenuItem>
        ))}
      </Select>
      <Switch>
        <Route path="/business">          
          <BubbleChart data={bubbleData} sliderDate={sliderDate} selectedAttribute={selectedAttribute} />
        </Route>
        <Route path="/alignment">          
          <BubbleChart data={bubbleData} sliderDate={sliderDate} selectedAttribute={selectedAttribute} />
        </Route>
      </Switch>
      {sliderDates.length > 0 &&
        <TimeControl 
          sliderDates={sliderDates} 
          sliderDate={sliderDate} 
          setSliderDate={setSliderDate} 
        />
      }
    </div>
  )
})

export default withRouter(VisualizationContainer)