import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import LandingPage from './components/LandingPage/LandingPage';
import ManageContainer from './components/manage/ManageContainer'
import SiteContainer from './components/SiteContainer'
import DataInitializer from './DataInitializer'

function App() {
  return (
    <div className="App">
      <DataInitializer />
      <Router>
        <Switch>
          <Route path="/modify">
            <ManageContainer />
          </Route>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="*/index.html">
            <LandingPage />
          </Route>
          <Route path="/">
            <SiteContainer />
          </Route>         
        </Switch>
      </Router>
    </div>
  );
}

export default App;
