import React from 'react';
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css';
import './ContentContainer.css'

function ContentContainer({children, className, onClick})  {
  return (
    <SimpleBar className={`content-container ${className}`} 
    onClick={onClick}
    >
      {children}
    </SimpleBar>
  )
}

export default ContentContainer