let dataService

const isElectron = window?.process?.type
const useLocalStorage = process.env.REACT_APP_DATA_SERVICE === "LOCAL_STORAGE"

if (isElectron) {
  dataService = require('./electronDataStore').default
} else if(useLocalStorage) {
  dataService = require('./localStorageDataStore').default
} else {
  dataService = require('./localDataStore').default
}

dataService.resetRepoToDefault = async () => {
  const data = await require('./defaultData.json')
  await dataService.updateStores(data)
}

dataService.resetRepoToClean = () => {
  dataService.updateStores({
    version: 1,
    datatTimestamp: Date.now(),
    repo: {
      purposes: [],
      strategies: [],
      tactics: [],
      projects: [],
      projectEvents: [],
      portfolios: [],
      performanceMetrics: [],
      performanceAttributes: []
    }
  })
}

export default dataService