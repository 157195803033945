// strategy, goals and tactics
import { makeAutoObservable, autorun, toJS } from "mobx"

class FeatureStore {
    features = {
      enableStrategyUI: false
    }

    constructor () {
      makeAutoObservable(this)
      this.load()
      this.autoSave()
    }

    autoSave = () => {
      let firstRun = true
      autorun(() => {
        // This code will run every time any observable property
        // on the store is updated.
        const storeString = JSON.stringify(toJS(this))
        if (!firstRun) {
          this.save(storeString)
        }
        firstRun = false
      })
    }

    load = () => {
      const storeString = window.localStorage.getItem('BASS.featureState')
      if (storeString) {
        const data = JSON.parse(storeString)

        this.features = {
          ...this.features,
          ...data.features
        }
      }
    }
  
    save = (storeString) => {
      window.localStorage.setItem('BASS.featureState', storeString)
    }

    setFeatureState = (featureKey, value) => {
      this.features = {
        ...this.features,
        [featureKey]: value
      }
    }
}

const featureStore = new FeatureStore()

export { featureStore }