import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Tab, Tabs, Box } from '@material-ui/core';
import tableConfigs from './tables/tableConfigs'
import EditableTableContainer from './tables/EditableTableContainer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const sidebarWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  sidebar: {
    width: `${sidebarWidth}px`,
    borderRight: '1px solid #979797'
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 48px)',
  }
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Projects",
      resourcePath: "projects",
      configName: "project"
    },
    {
      label: "Portfolios",
      resourcePath: "portfolios",
      configName: "portfolio"
    },
    {
      divider: true
    },
    {
      label: "Goals",
      resourcePath: "goals",
      configName: "purpose"
    },
    {
      label: "Strategies",
      resourcePath: "strategies",
      configName: "strategy"
    },
    {
      label: "Tactics",
      resourcePath: "tactics",
      configName: "tactic"
    },
    {
      divider: true
    },
    {
      label: "Project Events",
      resourcePath: "projectEvents",
      configName: "projectEvent"
    },
    {
      label: "Performance Attributes",
      resourcePath: "performanceAttributes",
      configName: "performanceAttribute"
    },
    {
      label: "Performance Metrics",
      resourcePath: "performanceMetrics",
      configName: "performanceMetric"
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tabData, i) => {
            if (tabData.divider) {
              return <Divider key={i}/>
            }
            return <Tab key={tabData.label} style={{margin: 'auto'}} label={tabData.label} {...a11yProps(i)} />
          })}
        </Tabs>
      </div>
      <div className={classes.contentContainer}>
        {tabs.map ((tabData, i) => {
          if (tabData.divider) {
            return null
          }
          return <TabPanel key={tabData.label} value={value} index={i}>
            <EditableTableContainer resourcePath={tabData.resourcePath} configColumns={tableConfigs[tabData.configName]} />
          </TabPanel>
        })}
      </div>
    </div>
  );
}
