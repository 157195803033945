const localDataStore = require('./localDataStore').default

const saveRepo = () => {
  console.log("SAVING DATA TO LOCAL STORAGE")
  window.localStorage.setItem('bass_store', JSON.stringify(localDataStore.getRepo()))
}

const dataService = {
  put: (location, data) => {
    localDataStore.put(location, data)
    saveRepo()
  },
  post: (location, data) => {
    localDataStore.post(location, data)
    saveRepo()
  },
  get: async (location) => {
    return await localDataStore.get(location)
  },
  delete: (location) => {
    localDataStore.delete(location)
    saveRepo()
  },
  loadDefaultData: async () => {
    const localAppDataStr = window.localStorage.getItem('bass_store')
    if(localAppDataStr) {
      const localAppData = JSON.parse(localAppDataStr)
      console.log("LOADING DATA FROM LOCAL STORAGE: ", localAppData)
      localDataStore.updateStores(localAppData)
    } else {
      localDataStore.loadDefaultData()
    }
  },
  getRepo: localDataStore.getRepo,
  updateStores: (data) => {
    localDataStore.updateStores(data)
    saveRepo()
  },
  getDataFromStores: localDataStore.getDataFromStores,
  updateCollaborationData: (data) => {
    localDataStore.updateCollaborationData(data)
    saveRepo()
  }
}
export default dataService