import React from 'react'
import { Container, List, ListItem, ListItemText } from '@material-ui/core';
// import { HomeRounded, AccountBalanceRounded, PieChartRounded } from '@material-ui/icons'
// import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import { ReactComponent as Logo } from '../../icons/collinearLogo.svg'
import icon from '../../icons/BASS-icon.png'
import { FiDatabase } from 'react-icons/fi'
import { CgSidebarOpen } from 'react-icons/cg'
import { GiHamburgerMenu } from 'react-icons/gi'


import './Sidebar.css'
import { Link, withRouter } from 'react-router-dom';

function Sidebar ({homeCallback, location, isOpen, toggleSidebar}) {
  let className = 'temp-sidebar'
  isOpen  ?  className = 'temp-sidebar' : className = 'temp-sidebar-closed'
  return (
    <Container
      id="sidebar"
      className={className}
      disableGutters
      // PaperProps={{ id:"sidebar" }}
      // open
      // variant="persistent"
    >
      <div className="sidebar-logo-container">
        {isOpen ? <Logo /> : <img src = {icon} alt = ''/>}
        {isOpen 
          ? <CgSidebarOpen className="sidebar-icon" onClick={toggleSidebar} /> 
          : <GiHamburgerMenu className="sidebar-icon" onClick={toggleSidebar} />}
      </div>
      <List>
        <Link to="/business">
          <ListItem
            classes={{
              selected: "selected"
            }}
            button
            disableGutters
            selected={location.pathname.startsWith("/business")}
            className="sidebar-list-item"
            onClick={homeCallback}
            id='sidebar-business'
          >
            {/* <ListItemIcon >
              <AccountBalanceRounded className="sidebar-list-item-icon"/>
            </ListItemIcon> */}
            <ListItemText>
              <div className="menu-bar-first-letter">B</div><span>usiness</span>
            </ListItemText>
          </ListItem>
        </Link>
        <Link to="/alignment">
          <ListItem
            classes={{
              selected: "selected"
            }}
            button
            disableGutters
            selected={location.pathname.startsWith("/alignment")}
            className="sidebar-list-item"
            onClick={homeCallback}
            id='sidebar-alignment'
          >
            {/* <ListItemIcon >
              <HomeRounded className="sidebar-list-item-icon"/>
            </ListItemIcon> */}
            <ListItemText>
              <div className="menu-bar-first-letter">A</div><span>lignment</span>
            </ListItemText>
          </ListItem>
        </Link>
        <Link to="/strategy">
          <ListItem
            classes={{
              selected: "selected"
            }}
            button
            disableGutters
            selected={location.pathname.startsWith("/strategy")}
            className="sidebar-list-item"
            onClick={homeCallback}
          >
            {/* <ListItemIcon > */}
              {/* <AccountBalanceRounded className="sidebar-list-item-icon"/> */}
              {/* <PieChartRounded /> */}
            {/* </ListItemIcon> */}
            <ListItemText>
              <div className="menu-bar-first-letter">S</div><span>trategy</span>
            </ListItemText>
          </ListItem>
        </Link>
        <Link to="/system">
          <ListItem
            classes={{
              selected: "selected"
            }}
            button
            disableGutters
            selected={location.pathname.startsWith("/system")}
            className="sidebar-list-item"
            onClick={homeCallback}
            id='sidebar-system'
          >
            {/* <ListItemIcon >
              <DateRangeRoundedIcon className="sidebar-list-item-icon"/>
            </ListItemIcon> */}
            <ListItemText>
              <div className="menu-bar-first-letter">S</div><span>ystem</span>
            </ListItemText>
          </ListItem>
        </Link> 
      </List>
      <Link to='/modify' className='menu-bar-manage-data'>
      <FiDatabase /> {isOpen ? <span>Manage Data</span> : ''}
      </Link>

    </Container>
  )
}

export default withRouter(Sidebar)