import React, { useState, useEffect, useMemo } from 'react';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import dataService from '../../services/dataService'
import EditDialog from './EditDialog'
import DeleteWarningDialog from './DeleteWarningDialog'
import { strategyStore } from '../../stores'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { useDrop } from 'react-dnd';
import TierTag from './TierTag';
import classNames from 'classnames'
import './ContentHeader.css'
import './ContentDisplay.css'


const colorClassMapping = {
  'STRATEGIES': 'strategy',
  'TACTICS': 'tactic',
  'PROJECTS': 'project'
}

const childTypeMapping = {
  'PURPOSES': 'STRATEGIES',
  'STRATEGIES': 'TACTICS',
  'TACTICS': 'PROJECTS'
}

const dropAccepts = {
  STRATEGIES: 'TACTICS',
  TACTICS: 'PROJECTS'
}

const ContentDisplay = observer(({ type, children, expandable, expanded, name, onClick, style, selected, hasPeers, activityId, hasChildren, selectParent }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [activity, setActivity] = useState({})
  const [activityToEdit, setActivityToEdit] = useState(null)
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)

  const canDelete = useMemo(() => {
    if (type === 'PROJECTS') {
      const hasEvents = strategyStore.projectEvents.some(event => event.projectId === activityId)
      const hasMetrics = strategyStore.performanceMetrics.some(metric => metric.projectId === activityId)
      return !hasEvents && !hasMetrics
    }
    return !hasChildren
  // eslint-disable-next-line
  }, [activityId, type, hasChildren, strategyStore.projectEvents, strategyStore.performanceMetrics])

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: dropAccepts[type] || 'NONE',
    drop: async (item) => { 
      // console.log(`Moving ${item.type} ${item.id} from ${item.parentId} to ${activityId}`)
      const record = toJS(strategyStore[item.type.toLowerCase()].find(n => n.id === item.id))
      if(record.tacticId === item.parentId) {
        record.tacticId = activityId
      } else if(record.strategyId === item.parentId) {
        record.strategyId = activityId
      }
      await dataService.put(`/${item.type.toLowerCase()}/${record.id}`, record)
    },
    canDrop: (item, monitor) => { 
      return activityId !== item.parentId
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
  }),
  })

  const { dataFileTimestamp } = strategyStore

  useEffect(() =>{
    const resourceType = type.toLowerCase()
    const a = strategyStore[resourceType].find(a => a.id === activityId)
    setActivity(a)
  }, [activityId, dataFileTimestamp, type])

  const mainDivClasses = classNames(
    `content-display`,
    `${type}-container`,
    `${colorClassMapping[type]}-color`,
    `${colorClassMapping[type]}-background-color`,
    `${colorClassMapping[type]}-border-color`,
    { expanded },
    { 'content-display-selected': selected },
    { 'has-peers': hasPeers },
    { 'valid-drop-target': canDrop },
    { 'hovering-over-drop-target': canDrop && isOver }
  )

  const addButtonClasses = classNames(
    'content-display-add-button',
    `${colorClassMapping[childTypeMapping[type]]}-color`,
    `${colorClassMapping[childTypeMapping[type]]}-background-color`,
    `${colorClassMapping[childTypeMapping[type]]}-border-color`
  )

  return(
    <div ref={drop} className={mainDivClasses} onClick={onClick} style={style}>
      <div className="content-display-top">
        <div className="content-display-name">
          {activity.name}
          {selected && 
            <>
              <EditIcon
                className="content-display-header-icon"
                onClick={() => {
                  setActivityToEdit(activity)
                  setShowEditModal(true) 
                }}
              />
              { canDelete && 
                <DeleteIcon
                  className="content-display-header-icon delete-icon"
                  onClick={() => {
                    setShowDeleteWarningModal(true)
                  }}
                />
              }
            </>
          }
        </div>
        {
          showEditModal && 
          <EditDialog 
            closeHandler={() => {
              setShowEditModal(false)
              setActivityToEdit(activity)
            }}
            type={activityToEdit ? type : childTypeMapping[type]}
            activity={activityToEdit}
            parentId={activityToEdit ? activityToEdit.parentId : activityId}
          />
        }
        <TierTag 
          title={colorClassMapping[type]} 
          tag={type.substring(0, 2)} 
          type={type} 
          itemId={activityId} 
          parentId={activity.tacticId || activity.strategyId || activity.purposeId}
        />
      </div>
      <div>{expanded && children}</div>
      { selected && childTypeMapping[type] &&
        <>
          <div className="bottom-placeholder-div"></div>
          <div 
            className={addButtonClasses}
            onClick={() => {
              setActivityToEdit(null)
              setShowEditModal(true) 
            }}
          >
            <AddIcon />
          </div>
        </>
      }
      {
        showDeleteWarningModal &&
        <DeleteWarningDialog 
          closeHandler={() => {
            setShowDeleteWarningModal(false)
            selectParent()
          }}
          resourceType={type.toLowerCase()}
          activityId={activityId}
        />
      }
    </div>
  )
})

export default ContentDisplay