import React from 'react';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';

const columns = [
  {
    accessor: 'name',
    Header: 'NAME',
    disableGroupBy: true,
    Cell: ({ value, row: { index }, column, row, updateTableData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return ( isEditable ?
        <TextField
          key={column.id + row.id}
          variant="outlined"
          value={value || ""}
          onChange={(e) => updateTableData(index, "name", e.target.value)}
        />
        :
        <p>{value}</p>
      )
    }
  },
  {
    accessor: 'strategyId',
    Header: 'STRATEGY',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { strategies = {} } = allData
      const strategy = strategies[value]
      return ( isEditable ?
        <Autocomplete
          options={Object.values(strategies)}
          getOptionLabel={(strategy) => strategy.name || "" }
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'strategyId', newVal?.id)
          }}
          value={strategy || null}
          renderInput={(params) => {
            return <TextField 
                    label="Select Strategy"
                    variant="outlined"
                    {...params}
                    value={params.value || ''}
                  />
        }}
        />
        :
        <p>{strategy?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'description',
    Header: 'DESCRIPTION',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows}) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return isEditable ?
        <ReactQuill
          bounds=".editable-table"
          theme="snow"
          value={value}
          onChange={(content, delta, source, editor) => updateTableData(index, 'description', content)}
          placeholder="Add a Description..."
          preserveWhitespace
        />
        : <div className="description-cell">{value}</div>
    }
  }
]


export default columns