import React from 'react'
import { FormControlLabel, List, ListItem, Switch } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { featureStore } from '../../stores'

const FeatureToggleList = observer(() => {
  const { features, setFeatureState } = featureStore
  
  const FeatureToggle = ({ label, featureKey }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={features[featureKey]}
            onChange={(e) => setFeatureState(featureKey, e.target.checked)}
            name={label}
            color="primary"
          />
        }
        label={label}
      />
    )
  }

  return (
    <List>
      <ListItem>
        <FeatureToggle
          label={"Enable New Strategy UI"}
          featureKey="enableStrategyUI"
        />
      </ListItem>
    </List>
  )
})

export default FeatureToggleList