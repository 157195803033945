import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import momentUtils from '@date-io/moment'
import moment from 'moment'

const columns = [
  {
    accessor: 'name',
    Header: 'NAME',
    Cell: ({ value, row: { index }, column, row, updateTableData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (isEditable ?
        <TextField
          key={column.id + row.id}
          variant="outlined"
          value={value || ""}
          onChange={(e) => updateTableData(index, "name", e.target.value)}
        />
        :
        <p>{value || ""}</p>
      )
    }
  },
  {
    accessor: 'projectId',
    Header: 'PROJECT',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { projects = {} } = allData
      const project = projects[value]
      return (isEditable ?
        <Autocomplete
          options={Object.values(projects)}
          getOptionLabel={(project) => project.name || "" }
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'projectId', newVal?.id)
          }}
          value={project || null}
          renderInput={(params) => {
            return <TextField 
                    label="Select Project"
                    variant="outlined"
                    {...params}
                    value={params.value || ''}
                  />
        }}
       /> :
       <p>{project?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'dateTime',
    Header: 'DATETIME',
    sortType: 'dateString',  
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (isEditable ?
        <MuiPickersUtilsProvider utils={momentUtils}>
          <KeyboardDatePicker 
            label="DateTime"
            value={value || null}
            onChange={(newDate) => updateTableData(index, "dateTime", newDate.toISOString())}
            inputVariant="outlined"
            format="MM/DD/YYYY"
            autoOk
          />
        </MuiPickersUtilsProvider> 
        :
        <p>{value ? moment(value).format('MM/DD/YYYY') : ''}</p>
      )
    }
  }
]


export default columns