import React, { useState } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite'
import { ReactComponent as CollinearIcon } from '../../icons/collinearLogo.svg'
import FishPhoto from '../../icons/BASS-icon.png'
import UserBadge from '../UserBadge';

import { strategyStore } from '../../stores'
import './LandingPage.css'

const LandingPage = observer(({ location }) => {
  const [businessHover, setBusinessHover] = useState(false)
  const [alignmentHover, setAlignmentHover] = useState(false)
  const [strategyHover, setStrategyHover] = useState(false)
  const [systemHover, setSystemHover] = useState(false)
  const {dataSetName} = strategyStore

  return (
    <div className="landing-page-container">
      <div className="landing-page-header">
        <CollinearIcon/>
        <UserBadge name="Collinear Group" role={dataSetName ? dataSetName : 'Local Data'} userImage={FishPhoto} />
      </div>
      <div className="landing-page-list">
        <div className="landing-page-select-one">
          <div>
            SELECT ONE
          </div>
        </div>
        <div className="landing-page-list-container">
          <List>
              <ListItem
                button
                disableGutters
                selected={location.pathname.startsWith("/business")}
                onMouseEnter={()=> setBusinessHover(true)}
                onMouseOut={()=> setBusinessHover(false)}
              >
                <Link to="/business"
                  onMouseEnter={()=> setBusinessHover(true)}
                  onMouseOut={()=> setBusinessHover(false)}
                >
                  <ListItemText style={{pointerEvents:'none'}}>
                    <div className={`landing-page-letter-surround${businessHover ? ' landing-page-hover-border' : ''}`}>
                      <div className={`landing-page-first-letter${businessHover ? ' landing-page-hover-border-right landing-page-text-hover' : ''}`}>B</div>
                      <div className={`landing-page-end-letters${businessHover ? ' landing-page-text-hover' : ''}`}>USINESS</div>
                    </div>
                  </ListItemText>
                </Link>
              </ListItem> 
              <ListItem
                button
                disableGutters
                selected={location.pathname.startsWith("/alignment")}
                onMouseEnter={()=> setAlignmentHover(true)}
                onMouseOut={()=> setAlignmentHover(false)}
              >
                <Link to="/alignment"
                  onMouseEnter={()=> setAlignmentHover(true)}
                  onMouseOut={()=> setAlignmentHover(false)}
                >
                  <ListItemText style={{pointerEvents:'none'}}>
                    <div className={`landing-page-letter-surround${alignmentHover ? ' landing-page-hover-border' : ''}`}>
                      <div className={`landing-page-first-letter${alignmentHover ? ' landing-page-hover-border-right landing-page-text-hover' : ''}`}>A</div>
                      <div className={`landing-page-end-letters${alignmentHover ? ' landing-page-text-hover' : ''}`}>LIGNMENT</div>
                    </div>
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem
                button
                disableGutters
                selected={location.pathname.startsWith("/strategy")}
                onMouseEnter={()=> setStrategyHover(true)}
                onMouseOut={()=> setStrategyHover(false)}
              >
                <Link to="/strategy"
                  onMouseEnter={()=> setStrategyHover(true)}
                  onMouseOut={()=> setStrategyHover(false)}
                  id='strategy-link'
                >
                  <ListItemText style={{pointerEvents:'none'}}>
                    <div className={`landing-page-letter-surround${strategyHover ? ' landing-page-hover-border' : ''}`}>
                      <div className={`landing-page-first-letter${strategyHover ? ' landing-page-hover-border-right landing-page-text-hover' : ''}`}>S</div>
                      <div className={`landing-page-end-letters${strategyHover ? ' landing-page-text-hover' : ''}`}>TRATEGY</div>
                    </div>
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem
                button
                disableGutters
                selected={location.pathname.startsWith("/system")}
                onMouseEnter={()=> setSystemHover(true)}
                onMouseOut={()=> setSystemHover(false)}
              >
                  <Link to="/system"
                    onMouseEnter={()=> setSystemHover(true)}
                    onMouseOut={()=> setSystemHover(false)}
                  >
                  <ListItemText style={{pointerEvents:'none'}}>
                    <div className={`landing-page-letter-surround${systemHover ? ' landing-page-hover-border' : ''}`}>
                      <div className={`landing-page-first-letter${systemHover ? ' landing-page-hover-border-right landing-page-text-hover' : ''}`}>S</div>
                      <div className={`landing-page-end-letters${systemHover ? ' landing-page-text-hover' : ''}`}>YSTEM</div>
                    </div>
                  </ListItemText>
                </Link> 
              </ListItem>
          </List>
        </div>
      </div>
    </div>
  )
})

export default withRouter(LandingPage)