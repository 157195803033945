import { strategyStore } from '../stores'
import { toJS } from 'mobx'
import { cleanUpDataAndReplaceIds } from './dataUtil'

import { collaborationDataService } from '.'

// const defaultRepo = require('./defaultData.json')
// const appData = defaultRepo || {}
const { v4: uuid } = require('uuid')

const DATA_FILE_VERSION = 1

const updateStores = (repoData) => {

    if (!repoData.repo) {
        return
    }

    let { version, dataTimestamp, repo } = repoData

    if (!version || !dataTimestamp) {
        repo = cleanUpDataAndReplaceIds(repo)
        version = version || DATA_FILE_VERSION
        dataTimestamp = dataTimestamp || Date.now()
    }

    const {
        purposes,
        strategies,
        tactics,
        projects,
        projectEvents,
        portfolios,
        performanceMetrics,
        performanceAttributes
    } = repo

    strategyStore.dataFileVersion = version
    strategyStore.dataFileTimestamp = dataTimestamp

    const { dataSetName, dataSetAccessKey } = repoData
    strategyStore.setCollaborationKeys({ dataSetName, dataSetAccessKey })

    strategyStore.setPerformanceAttributes(performanceAttributes)

    strategyStore.setPorfolios(portfolios)
    strategyStore.setGoals(purposes)
    strategyStore.setStrategies(strategies)
    strategyStore.setTactics(tactics)

    strategyStore.setProjects(projects)
    strategyStore.setProjectEvents(projectEvents)

    strategyStore.setPerformanceMetrics(performanceMetrics)
}

const getDataFromStores = () => {
    const { goals, strategies, tactics } = strategyStore
    const { portfolios, projects, projectEvents } = strategyStore
    const { performanceAttributes, performanceMetrics } = strategyStore
    return {
        purposes: toJS(goals),
        strategies: toJS(strategies),
        tactics: toJS(tactics),
        portfolios: toJS(portfolios),
        projects: toJS(projects),
        projectEvents: toJS(projectEvents),
        performanceAttributes: toJS(performanceAttributes),
        performanceMetrics: toJS(performanceMetrics),
    }
}


const getRepo = () => {
    const { dataFileVersion: version, dataFileTimestamp: dataTimestamp, dataSetName, dataSetAccessKey } = strategyStore
    return {
        version,
        dataTimestamp,
        dataSetName,
        dataSetAccessKey,
        repo: getDataFromStores()
    }
}

const dataService = {
    cleanUpDataAndReplaceIds,
    updateStores,
    getDataFromStores,
    getRepo,
    put: (location, data) => {
        const [, loc, ] = location.split('/')
        strategyStore.updateEntity(loc, data)
        strategyStore.updateDataTimestamp()
        if (strategyStore.dataSetName) { collaborationDataService.updateCatalog(getRepo()) }
    },
    post: (location, data) => {
        strategyStore.addEntity(location.substr(1), {...data, id: uuid()})
        strategyStore.updateDataTimestamp()
        if (strategyStore.dataSetName) { collaborationDataService.updateCatalog(getRepo()) }
    },
    delete: (location) => {
        const [, loc, id] = location.split('/')
        strategyStore.removeEntity(loc, id)
        strategyStore.updateDataTimestamp()
        if (strategyStore.dataSetName) { collaborationDataService.updateCatalog(getRepo()) }
    },
    loadDefaultData: async () => {
        const data = await require('./defaultData.json')
        updateStores(data)
    },
    updateCollaborationData: (data) => {
        strategyStore.setCollaborationKeys(data)
    }
}
export default dataService