import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import DataTable from './tables/DataTable'
import { Redirect } from 'react-router-dom';
import ManageData from './ManageData'
import ManagePerformanceMetrics from './ManagePerformanceMetrics';
import CollaborationStatusIcon from './CollaborationStatusIcon';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div style={{ position: 'absolute', right: 10, top: 10 }}>
        <CollaborationStatusIcon />
      </div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab style={{margin: 'auto'}} label="Home" {...a11yProps(0)} />
          <Tab style={{margin: 'auto'}} label="Manage Data" {...a11yProps(1)} />
          <Tab style={{margin: 'auto'}} label="Manage Metrics" {...a11yProps(2)} />
          <Tab style={{margin: 'auto'}} label="Configuration" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Redirect to='/strategy' />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManageData />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ManagePerformanceMetrics />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DataTable />
      </TabPanel>
    </div>
  );
}
