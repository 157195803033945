const { app } = window.require('electron').remote
const fs = window.require('fs')
const appDataFileName = "bassData.json"
const appDataFilePath = `${app.getPath('appData')}/${app.getName()}/${appDataFileName}`

const localDataStore = require('./localDataStore').default

console.log("App data path: ", appDataFilePath)

const saveRepo = () => {
  fs.writeFileSync(appDataFilePath, JSON.stringify(localDataStore.getRepo()))
}

const dataService = {
  put: (location, data) => {
    localDataStore.put(location, data)
    saveRepo()
  },
  post: (location, data) => {
    localDataStore.post(location, data)
    saveRepo()
  },
  get: async (location) => {
    return await localDataStore.get(location)
  },
  delete: (location) => {
    localDataStore.delete(location)
    saveRepo()
  },
  loadDefaultData: async () => {
    const localAppData = fs.existsSync(appDataFilePath) && JSON.parse(fs.readFileSync(appDataFilePath, 'utf8'))
    console.log("LOADING DATA: ", localAppData)
    if (localAppData) {
      localDataStore.updateStores(localAppData)
    } else {
      localDataStore.loadDefaultData()
    }
  },
  getRepo: localDataStore.getRepo,
  updateStores: (data) => {
    localDataStore.updateStores(data)
    saveRepo()
  },
  getDataFromStores: localDataStore.getDataFromStores,
  updateCollaborationData: (data) => {
    localDataStore.updateCollaborationData(data)
    saveRepo()
  }
}
export default dataService