import React, { useEffect, useState, useMemo } from 'react'
import { keyBy } from 'lodash'
import EventList from './EventList'
import MetricsView from './MetricsView'

import { observer } from 'mobx-react-lite'
import { strategyStore } from '../../../stores'

const ProjectContent = observer((props) => {
  const { projectId } = props
  const [events, setEvents] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [attributes, setAttributes] = useState(null)

  const attributeMap = useMemo(() => {
    if (!attributes) {
      return null
    }
    return keyBy(attributes, 'id')
  }, [attributes])

  useEffect(()=> {
    const getEvents = async () => {
      setEvents(strategyStore.projectEvents.filter(pe => pe.projectId === projectId))
    }
    const getMetrics = async () => {
      setMetrics(strategyStore.performanceMetrics.filter(m => m.projectId === projectId))
    }
    const getAttributes = async () => {
      setAttributes(strategyStore.performanceAttributes)
    }

    getEvents()
    getMetrics()
    getAttributes()
  }, [projectId, setEvents, setMetrics, setAttributes])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: "40%", marginRight: "30px" }}>
        <EventList events={events}/>
      </div>
      <div style={{ width: "60%" }}>
        <MetricsView metrics={metrics} attributeMap={attributeMap}/>
      </div>
    </div>
  )
})

export default ProjectContent