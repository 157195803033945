import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { sortBy, groupBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Add as AddIcon, SpeakerNotes as SpeakerNotesIcon } from '@material-ui/icons'
import { strategyStore } from '../../stores'
import ContentHeader from './ContentHeader'
import ContentDisplay from './ContentDisplay'
import ContentContainer from './ContentContainer'
import EditDialog from './EditDialog'
import classNames from 'classnames'
import ProjectContent from './ProjectContent/ProjectContent'
import './ResourceTree.css'

const ResourceTree = observer(({ searchResult, resetTime, isOpen}) => {
  const [selectedPurposeId, setSelectedPurposeId] = useState(null)
  const [selectedStrategyId, setSelectedStrategyId] = useState(null)
  const [selectedTacticId, setSelectedTacticId] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [showGoalDescription, setShowGoalDescription] = useState(false)
  const [showAddStrategyDialog, setShowAddStrategyDialog] = useState(false)
  const [strategiesByPurposeId, setStrategiesByPurposeId] = useState({})
  const [tacticsByStrategyId, setTacticsByStrategyId] = useState({})
  const [projectsByTacticId, setProjectsByTacticId] = useState({})

  const showAddStrategyButton = useMemo(() => {
    return selectedPurposeId && !(selectedStrategyId || selectedTacticId || selectedProjectId)
  }, [selectedPurposeId, selectedStrategyId, selectedTacticId, selectedProjectId])

  useEffect(() => {
    const sortedStrategies = sortBy(strategyStore.strategies, 'name')
    const groupedStrategies = groupBy(sortedStrategies, 'purposeId')
    setStrategiesByPurposeId(groupedStrategies)
  // eslint-disable-next-line
  }, [strategyStore.strategies])

  useEffect(() => {
    const sortedTactics = sortBy(strategyStore.tactics, 'name')
    const groupedTactics = groupBy(sortedTactics, 'strategyId')
    setTacticsByStrategyId(groupedTactics)
  // eslint-disable-next-line
  }, [strategyStore.tactics])

  useEffect(() => {
    const sortedProjects = sortBy(strategyStore.projects, 'name')
    const groupedProjects = groupBy(sortedProjects, 'tacticId')
    setProjectsByTacticId(groupedProjects)
  // eslint-disable-next-line
  }, [strategyStore.projects])

  let style = {width:'79vw'}
  isOpen ? style={width:'79vw'} : style={width:'92vw'}

  const selectResource = useCallback((type, idStr) => {
    const id = idStr
    let projectId = null
    let tacticId = null
    let strategyId = null
    let purposeId = null

    switch(type) {
      case 'Project':
        if (!projectId) {
          projectId = id
        }
        tacticId = strategyStore.projects.find(p => p.id === projectId).tacticId
      // eslint-disable-next-line
      case 'Tactic':
        if (!tacticId) {
          tacticId = id
        }
        strategyId = strategyStore.tactics.find(t => t.id === tacticId).strategyId
      // eslint-disable-next-line
      case 'Strategy':
        if (!strategyId) {
          strategyId = id
        }
        purposeId = strategyStore.strategies.find(s => s.id === strategyId).purposeId
      // eslint-disable-next-line
      case 'Purpose':
      case 'Goal':
        if (!purposeId) {
          purposeId = id
        }
        setSelectedProjectId(projectId)
        setSelectedTacticId(tacticId)
        setSelectedStrategyId(strategyId)
        setSelectedPurposeId(purposeId)       
        break;
      default:
        setSelectedProjectId(null)
        setSelectedTacticId(null)
        setSelectedStrategyId(null)
        setSelectedPurposeId(null)
    }
  }, [
    setSelectedProjectId,
    setSelectedTacticId,
    setSelectedStrategyId,
    setSelectedPurposeId
  ])

  useEffect(() => {
    const {resourceType, resourceId} = searchResult || {}
    selectResource(resourceType, resourceId)
  }, [selectResource, searchResult ])

  useEffect(() => {
    if(!searchResult){
      selectResource(null, null)
    }
  }, [resetTime, selectResource, searchResult])
  
  const addStrategyButtonClasses = classNames(
    'content-display-add-strategy-button',
    `strategy-color`,
    `strategy-background-color`,
    `strategy-border-color`
  )

  return (
    <div id="resource-tree" style={style}>
      <div className={`resource-tree-header-container ${selectedPurposeId ? `hidden-scroll` : ''}`}>
        {sortBy(strategyStore.goals, 'name').map(goal => {
          const noneSelected = !selectedPurposeId
          // eslint-disable-next-line
          const currentlySelected = selectedPurposeId && selectedPurposeId == goal.id
          const shown = noneSelected || currentlySelected
          return (
            <ContentHeader
              key={goal.id}
              type="PURPOSE"
              goalId = {goal.id}
              style={{
                opacity: shown ? 1 : 0.5,
                background: shown ? undefined : 'transparent',
                borderBottom: noneSelected ? undefined : 'none'
              }}
              shown={shown}
              selected={currentlySelected}
              onClick={()=> {
                if (!selectedPurposeId) {
                  selectResource("Purpose", goal.id)
                } else if (selectedPurposeId !== goal.id) {
                  selectResource("Purpose", goal.id)
                } else if (selectedPurposeId === goal.id && (selectedProjectId || selectedStrategyId || selectedTacticId)) {
                  setSelectedProjectId(null)
                  setSelectedTacticId(null)
                  setSelectedStrategyId(null)
                } else {
                  selectResource(null, null)
                }
              }}
            />
          )
        })}
      </div>
      <div className="resource-tree-content-container">
        {sortBy(strategyStore.goals, 'name').map(goal => {
          if(selectedPurposeId && selectedPurposeId !== goal.id){
            return null
          }

          // eslint-disable-next-line
          const currentlySelected = selectedPurposeId == goal.id
          const goalStrategies = strategiesByPurposeId[goal.id] || []

          return (
            <ContentContainer key={goal.id} 
            className={selectedPurposeId ? `fixed-content-container` : ''}
            onClick={()=> selectResource("Purpose", goal.id)}           
            >
              {currentlySelected && 
                <div style={{ flexBasis: "100%" }}>
                {goal.description &&
                  <div className='resource-tree-goal-comments-icon' id='goal-description-icon'>
                    <SpeakerNotesIcon 
                    onClick={() => { setShowGoalDescription(!showGoalDescription) }} 
                    />
                  </div>
                }
                {goal.description && showGoalDescription &&
                  <div
                    className="description"
                    style={{color: 'var(--purpose-color)'}}
                    dangerouslySetInnerHTML={{ __html: goal.description }}
                  />
                }
                  <div className={`content-display-container${selectedStrategyId ? "full-width" : ""}`}>
                    { goalStrategies.map(strategy => {
                      let isSelected = false
                      if (selectedStrategyId) {
                        if (selectedStrategyId !== strategy.id) {
                          return null
                        } else if (!selectedTacticId) {
                          isSelected = true
                        }
                      }
                      const strategyTactics = tacticsByStrategyId[strategy.id] || []
                      return (
                        <ContentDisplay 
                          key={strategy.id} 
                          type="STRATEGIES" 
                          expandable={false} 
                          expanded={true} 
                          name={strategy.name}
                          selected={isSelected}
                          hasPeers={goalStrategies.length > 1 && selectedStrategyId}
                          hasChildren={!!strategyTactics.length}
                          onClick={(e) => {
                            e.stopPropagation()
                            selectResource("Strategy", strategy.id)
                          }}  
                          activityId={strategy.id}
                          selectParent={() => selectResource("Purpose", strategy.purposeId)}
                        >
                          { isSelected &&
                            <div
                              className="description"
                              dangerouslySetInnerHTML={{ __html: strategy.description }}
                            />
                          }
                          { strategyTactics.map(tactic => {
                            let isSelected = false
                            if (selectedTacticId) {
                              if (selectedTacticId !== tactic.id) {
                                return null
                              } else if (!selectedProjectId) {
                                isSelected = true
                              }
                            }
                            const tacticProjects = projectsByTacticId[tactic.id] || []
                            return (
                              <ContentDisplay
                                key={tactic.id} 
                                type="TACTICS" 
                                expandable={false} 
                                expanded={true} 
                                name={tactic.name}
                                selected={isSelected}
                                hasPeers={strategyTactics.length > 1 && selectedTacticId}
                                hasChildren={!!tacticProjects.length}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  selectResource("Tactic", tactic.id)
                                }}
                                activityId={tactic.id}
                                selectParent={() => selectResource("Strategy", tactic.strategyId)}
                              >
                                { isSelected &&
                                  <div
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: tactic.description }}
                                  />
                                }
                                { tacticProjects.map(project => {
                                  let isSelected = false
                                  if (selectedProjectId) {
                                    if (selectedProjectId !== project.id) {
                                      return null
                                    } else {
                                      isSelected = true
                                    }
                                  }
                                  return (
                                    <ContentDisplay
                                      key={project.id} 
                                      type="PROJECTS" 
                                      expandable={false} 
                                      expanded={isSelected} 
                                      name={project.name}
                                      selected={isSelected}
                                      hasPeers={tacticProjects.length > 1 && selectedProjectId}
                                      hasChildren={false}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        selectResource("Project", project.id)
                                      }}
                                      activityId={project.id}
                                      selectParent={() => selectResource("Tactic", project.tacticId)}
                                    >
                                      <div
                                        className="description"
                                        dangerouslySetInnerHTML={{ __html: project.description }}
                                      />
                                      <ProjectContent projectId={project.id} />
                                    </ContentDisplay>
                                  )
                                })}
                              </ContentDisplay>
                            )
                          })}
                        </ContentDisplay>
                      )
                    })}
                  </div>
                </div>
              }
              {!currentlySelected &&
                <div className="content-type-badge-container">
                  <div style={{ flexBasis: "100%" }}>
                  {goalStrategies.map(strategy => <ContentDisplay
                    key={strategy.id}
                    type="STRATEGIES"
                    name={strategy.name}
                    onClick={(e) => {
                      e.stopPropagation()
                      selectResource("Strategy", strategy.id)
                    }}
                    activityId={strategy.id}
                  >
                  </ContentDisplay>)}
                  </div>
                </div>
              }
              {
                showAddStrategyDialog && 
                <EditDialog 
                  closeHandler={() => {
                    setShowAddStrategyDialog(false)
                  }}
                  type='STRATEGIES'
                  activity={null}
                  parentId={selectedPurposeId}
                />
              }
              { showAddStrategyButton &&
                <div className="add-strategy-button-container">
                  <div className="bottom-placeholder-div"></div>
                  <div 
                    className={addStrategyButtonClasses}
                    onClick={() => {
                      setShowAddStrategyDialog(true) 
                    }}
                  >
                    <AddIcon />
                  </div>
                </div>
              }
            </ContentContainer>
          )
        })}
      </div>
    </div>
  )
})

export default ResourceTree