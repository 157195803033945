// strategy, goals and tactics
import { makeAutoObservable } from "mobx"

class StategyStore {
    goals = []
    strategies = []
    tactics = []

    performanceMetrics = []
    performanceAttributes = []

    projects = []
    portfolios = []
    projectEvents = []

    collaborationData = {
        status: 'Not Initialized',
        dataSets: []
    }
  
    dataFileVersion = 1
    dataFileTimestamp = null
    dataSetName = null
    dataSetAccessKey = null

    constructor () {
        makeAutoObservable(this)
    }

    setGoals(goals) {
        this.goals = goals
    }
    setStrategies(strategies) {
        this.strategies = strategies
    }
    setTactics(tactics) {
        this.tactics = tactics
    }
    setPerformanceMetrics(performanceMetrics) {
      this.performanceMetrics = performanceMetrics
    }
  
    setPerformanceAttributes(performanceAttributes) {
      this.performanceAttributes = performanceAttributes
    }

    setProjects(projects) {
        this.projects = projects
    }

    setPorfolios(portfolios) {
        this.portfolios = portfolios
    }

    setProjectEvents(projectEvents) {
        this.projectEvents = projectEvents
    }

    addEntity(type, data) {
        if (this[type]) {
            this[type] = [...this[type], data]
        } else {
            console.log("TYPE NOT FOUND: ", type)
        }
    }

    updateEntity(type, data) {
        if (this[type]) {
            this[type] = [...this[type].filter(d => d.id !== data.id), data]
        } else {
            console.log("TYPE NOT FOUND: ", type)
        }
    }
    removeEntity(type, id) {
        if (this[type]) {
            this[type] = this[type].filter(d => d.id !== id)
        } else {
            console.log("TYPE NOT FOUND: ", type)
        }
    }

    setCollaborationData(data) {
        this.collaborationData = data
    }

    setCollaborationKeys({ dataSetName, dataSetAccessKey }) { 
        this.dataSetName = dataSetName
        this.dataSetAccessKey = dataSetAccessKey
    }

    updateDataTimestamp() {
        const newTimestamp = Date.now()
        this.dataFileTimestamp = newTimestamp
        return newTimestamp
    }

}

const strategyStore = new StategyStore()

export { strategyStore }