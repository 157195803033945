const { v4: uuid } = require('uuid')

const pluralName = prop => (prop === 'strategy' ? 'strategies' : `${prop}s`)

export function cleanUpDataAndReplaceIds(data) {
  for (const branchName in data) {
    for (let entity of data[branchName]) {
      //Assign unique IDs to all entities
      entity._id = uuid()

      //Remove legacy data structures
      delete entity.project
      delete entity.purpose
      delete entity.strategyIds
      delete entity.tacticIds
      delete entity.projectIds
      delete entity.performanceMetricIds
    }
  }
  //Replace references to entity IDs to use the new uuid
  for (const branchName in data) {
    for (let entity of data[branchName]) {
      for (let propName in entity) {
        if (propName.substr(-2) === 'Id') {
          let atName = propName.substr(0, propName.length - 2)
          const refEntity = data[pluralName(atName)].find(e => e.id === entity[propName])
          entity[propName] = refEntity._id
        }
      }
    }
  }
  //Remove the placeholder _id
  for (const branchName in data) {
    for (let entity of data[branchName]) {
      entity.id = entity._id
      delete entity._id
    }
  }
  console.warn("Upgraded data file")
  return data
}
