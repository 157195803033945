import React, { useState, useEffect, useMemo } from 'react'
import Timeline from 'react-calendar-timeline'
import SearchBox from '../search/OtherOtherSearchBox'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import moment from 'moment'
import { uniqBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { strategyStore } from '../../stores'
import 'react-calendar-timeline/lib/Timeline.css'
import './EventTimeline.css'

const EventTimeline = observer(() => {
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  const [filterBy, setFilterBy] = useState(null)

  const { projectEvents, projects, tactics, strategies } = strategyStore

  const filteredProjectEvents = useMemo(() => {
    const projectIdsByHierarchy = (filter => {
      if(!filter) {
        return projects.map(p => p.id)
      }
      switch (filter.resourceType) {
        case 'Goal':
          const filteredStrategyIds = strategies.filter(s => s.purposeId === filter.resourceId).map(s => s.id)
          const filteredTacticIds = tactics.filter(t => filteredStrategyIds.includes(t.strategyId)).map(t => t.id)
          return projects.filter(p => filteredTacticIds.includes(p.tacticId)).map(p => p.id)
        case 'Strategy':
          const filteredTacticIdsByStrategy = tactics.filter(t => t.strategyId === filter.resourceId).map(t => t.id)
          return projects.filter(p => filteredTacticIdsByStrategy.includes(p.tacticId)).map(p => p.id)
        case 'Tactic':
          return projects.filter(p => p.tacticId === filter.resourceId).map(p => p.id)
        case 'Project':
          return projects.filter(p => p.id === filter.resourceId).map(p => p.id)
        default:
          return projects.map(p => p.id)
      }
    })

    return projectEvents.filter(pe => projectIdsByHierarchy(filterBy).includes(pe.projectId))
  }, [projectEvents, filterBy, projects, strategies, tactics])

  

  useEffect(() => {
    const generateChart = (filteredProjectEvents) => {
      if (!filteredProjectEvents) {
        return
      }
      const eventProjects = uniqBy(filteredProjectEvents.map(projectEvent => {
        const { id: projectId, name: projectTitle } = projects.find(p => p.id === projectEvent.projectId)
        return { id: projectId, title: projectTitle, height: 50 }
      }), 'id')

      const items = filteredProjectEvents.map(projectEvent => {
        return {
          id: projectEvent.id,
          group: projectEvent.projectId,
          title: projectEvent.name,
          start_time: moment(projectEvent.dateTime),
          end_time: moment(projectEvent.dateTime).add(1, 'minute'),
          itemProps: { style: { color: 'white', fontWeight: 'bold' } }
        }
      })

      setItems(items)
      setGroups(eventProjects)
    }

    generateChart(filteredProjectEvents)
  }, [filteredProjectEvents, projects])


  return (
    <div id='events'>
      <SearchBox setSelectedSearchResult={setFilterBy} event={true}/>
      <Timeline
        style={{ background: '#212027', color: 'white' }}
        sidebarWidth={250}
        groups={groups}
        items={items}
        defaultTimeStart={moment().startOf('day')}
        defaultTimeEnd={moment().add(30, 'day')}
        canMove={false}
        canResize={false}
        resizeDetector={containerResizeDetector}
      />
    </div>
  )
})

export default EventTimeline