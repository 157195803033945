import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import momentUtils from '@date-io/moment'
import moment from 'moment'

const columns = [
  {
    accessor: 'performanceAttributeId',
    Header: 'PERFORMANCE ATTRIBUTE',
    width: 100,
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { performanceAttributes = {} } = allData
      const performanceAttribute = performanceAttributes[value]
      return ( isEditable ?
        <Autocomplete
          options={Object.values(performanceAttributes)}
          getOptionLabel={(performanceAttribute) => {
            const { name, unit } = performanceAttribute
            return unit ? `${name} (${unit})` : name
          }}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'performanceAttributeId', newVal?.id)
          }}
          value={performanceAttribute || null}
          id='select-performance-attribute-metric'
          renderInput={(params) => {
            return <TextField
              label="Select Performance Attribute"
              variant="outlined"
              {...params}
              value={params.value || ''}
              id='select-performance-attribute-metric'
            />
          }}
        /> : <p>{performanceAttribute?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'projectId',
    Header: 'PROJECT',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { projects = {} } = allData
      const project = projects[value]
      return ( isEditable ?
        <Autocomplete
          options={Object.values(projects)}
          getOptionLabel={(project) => project.name || ""}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'projectId', newVal?.id)
          }}
          value={project || null}
          id='select-project-metric'
          renderInput={(params) => {
            return <TextField
              label="Select Project"
              variant="outlined"
              {...params}
              value={params.value || ''}
            />
          }}
        /> : <p>{project?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'value',
    Header: 'VALUE',
    width: 50,
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return (isEditable ?
        <TextField
          type="number"
          label="Value"
          value={value || ""}
          variant="outlined"
          id='select-value-metric'
          onChange={(e) => updateTableData(index, "value", e.target.value)}
        /> : <p id='select-value-metric'
        >{value}</p>
      )
    }
  },
  {
    accessor: 'portfolioId',
    Header: 'PORTFOLIO',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { portfolios = {} } = allData
      const portfolio = portfolios[value]
      return ( isEditable ?
        <Autocomplete
          options={Object.values(portfolios)}
          getOptionLabel={(portfolio) => portfolio.name || ""}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'portfolioId', newVal?.id)
          }}
          value={portfolio || null}
          id='select-portfolio-metric'
          renderInput={(params) => {
            return <TextField
              label="Select Portfolio"
              variant="outlined"
              {...params}
              value={params.value || ''}
            />
          }}
        /> : <p>{portfolio?.name || ""}</p>
      )
    }
  },
  // {
  //   accessor: 'rangeLow',
  //   Header: 'RANGE LOW',
  //   Cell: ({ value, row: { index }, column, row, updateTableData, allData }) => {
  //     return (
  //       <TextField
  //         label="Range Low"
  //         value={value || ""}
  //         variant="outlined"
  //         onChange={(e) => updateTableData(index, "rangeLow", e.target.value)}
  //       />
  //     )
  //   }
  // },
  // {
  //   accessor: 'rangeHigh',
  //   Header: 'RANGE HIGH',
  //   Cell: ({ value,  row: { index }, column, row, updateTableData, allData }) => {
  //     return (
  //       <TextField
  //         label="Range High"
  //         value={value || ""}
  //         variant="outlined"
  //         onChange={(e) => updateTableData(index, "rangeHigh", e.target.value)}
  //       />
  //     )
  //   }
  // },
  {
    accessor: 'recordedDate',
    Header: 'RECORDED DATE',
    width: 80,
    sortType: 'basic',
    Cell: ({ value, row: { index }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return ( isEditable ?
        <MuiPickersUtilsProvider utils={momentUtils}>
          <KeyboardDatePicker
            disableToolbar
            label="Recorded Date"
            value={value || null}
            onChange={(newDate) => updateTableData(index, "recordedDate", newDate)}
            variant="inline"
            format="MM/DD/YYYY"
            id='select-recorded-date-metric'
            autoOk
          />
        </MuiPickersUtilsProvider> : <p>{value ? moment(value).format('MM/DD/YYYY') : ''}</p>
      )
    }
  },
]


export default columns