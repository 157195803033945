import React from 'react'
import { withRouter } from 'react-router-dom'
import Sidebar from '../Sidebar'
import Header from '../Header'
import './SiteContainer.css'
import MainContainer from '../MainContainer';

class SiteContainer extends React.Component  {
  constructor(props) {
    super();
    this.state ={
      resetTime:null,
      searchResult: null,
      isSidebarOpen: true,
    }
  }

  handleSetSelectedSearchResult =(searchResult) =>{
    if(this.props.location.pathname !== '/strategy'){
      this.props.history.push('/strategy')
    }
    this.setState({searchResult:searchResult})
  }

  handleViewSidebar =(e) => {
    let isSidebarOpen = this.state.isSidebarOpen
    this.setState({isSidebarOpen: !isSidebarOpen})
  }

  render() {
  return (
    <div id="site-container">
      <Sidebar onClick={this.handleViewSidebar} 
        isOpen={this.state.isSidebarOpen}
        toggleSidebar={this.handleViewSidebar}
        homeCallback={() => {
          this.setState({
            resetTime: Date.now()
          })
        }} 
      />
      <div style={{ width: "100%", height:"100vh" }}>
        <Header setSearchResult={this.handleSetSelectedSearchResult}/>
        <MainContainer 
          searchResult={this.state.searchResult} 
          resetTime={this.state.resetTime}
          isOpen={this.state.isSidebarOpen} />
      </div>
    </div>
  )}
}

export default withRouter(SiteContainer)