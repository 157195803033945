import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import momentUtils from '@date-io/moment'
import moment from 'moment'

const columns = [
  {
    accessor: 'portfolioId',
    Header: 'PORTFOLIO',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index, original }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { portfolios = {} } = allData
      const portfolio = portfolios[value]
      return ( isEditable && !original.existing  ?
        <Autocomplete
          options={Object.values(portfolios)}
          getOptionLabel={(portfolio) => portfolio.name || ""}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'portfolioId', newVal?.id)
          }}
          value={portfolio || null}
          id={`metric-value-portfolio`}
          renderInput={(params) => {
            return <TextField
              label="Select Portfolio"
              variant="outlined"
              {...params}
              value={params.value || ''}
            />
          }}
        /> : <p>{portfolio?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'projectId',
    Header: 'PROJECT',
    sortType: 'idColumnSort',
    filter: 'idColumnFilter',
    Cell: ({ value, row: { index, original }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      const { projects = {} } = allData
      const project = projects[value]
      return ( isEditable && !original.existing  ?
        <Autocomplete
          options={Object.values(projects)}
          getOptionLabel={(project) => project.name || ""}
          getOptionSelected={isEqual}
          onChange={(e, newVal) => {
            updateTableData(index, 'projectId', newVal?.id)
          }}
          value={project || null}
          id={`metric-value-project`}
          renderInput={(params) => {
            return <TextField
              label="Select Project"
              variant="outlined"
              {...params}
              value={params.value || ''}
            />
          }}
        /> : <p>{project?.name || ""}</p>
      )
    }
  },
  {
    accessor: 'recordedDate',
    Header: 'RECORDED DATE',
    width: 80,
    sortType: 'basic',
    Cell: ({ value, row: { index, original }, column, row, updateTableData, allData, editableRows }) => {
      const isEditable = editableRows.indexOf(row.id) >= 0
      return ( isEditable && !original.existing ?
        <MuiPickersUtilsProvider utils={momentUtils}>
          <KeyboardDatePicker
            disableToolbar
            label="Recorded Date"
            value={value || null}
            onChange={(newDate) => updateTableData(index, "recordedDate", newDate)}
            variant="inline"
            format="MM/DD/YYYY"
            autoOk
            id={`metric-value-recorded-date`}
          />
        </MuiPickersUtilsProvider> : <p>{value ? moment(value).format('MM/DD/YYYY') : ''}</p>
      )
    }
  }
]


export default columns