import React, { useMemo, useEffect, useState } from 'react'
import moment from 'moment'

function EventList(props) {
  const [events, setEvents] = useState([])
  
  useEffect(() => {
    setEvents(props.events || [])
  }, [props.events])

  const futureEvents = useMemo(() => {
    return events.filter(event => new Date() < new Date(event.dateTime)).reverse()
  }, [events])

  return (
    <div>
      <h4 style={{ margin: "0 0 10px" }}>Future Events</h4>
      <div style={{ overflowY: "scroll", paddingRight: "20px", borderTop: "1px solid var(--project-color)", maxHeight: "calc(40vh - 78px)" }}>
        {futureEvents.length ?
          futureEvents.map(event => {
            return (
              <div key={event.id} style={{ display: "flex", margin: "3px 0" }}>
                <div style={{ minWidth: '148px' }}>
                  <span>{moment(event.dateTime).format('MM/DD/YY hh:mm a')}</span>
                </div>
                <div>
                  -&nbsp;
                </div>
                <div>
                  <span>{event.name}</span>
                </div>
              </div>
            )
          })
          :
          <span>No Upcoming Events</span>
        }
      </div>
    </div>
  )
}

export default EventList