import React from 'react'

import { CloudDone, CloudOff, CloudDownload, Error } from '@material-ui/icons';

import { strategyStore } from '../../stores'
import { observer } from 'mobx-react-lite'

const CollaborationStatusIcon = observer(() => {
  if (!strategyStore.dataSetName) {
    return null
  }
  if (!strategyStore.collaborationData.status === 'Online') {
    return <CloudOff color='error' />
  }
  let color = 'primary'

  if (Date.now() - strategyStore.collaborationData.lastServerCheck > 30000) {
    color = 'error'
  }
  if (!strategyStore.collaborationData || Date.now() - strategyStore.collaborationData.lastServerCheck > 10000) {
    color = ''
  }

  if (strategyStore.collaborationData.lastServerStatus === 'Updating') {
    return <CloudDownload color={color} />
  }

  if (strategyStore.collaborationData.lastServerStatus === 'OK') {
    return <CloudDone color={color} />
  }

  return <Error color={'error'} />
})

export default CollaborationStatusIcon