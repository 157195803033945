import React, { Component } from 'react'
import * as d3 from 'd3'

class BubbleChart extends Component {
    constructor(props) {
        super(props)
        this.width = 600
        this.color = d3.scaleLinear()
            .domain([0, 4])
            .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
            .interpolate(d3.interpolateHcl)
        this.format = d3.format(",d")
        this.state = {
            focusName: null
        }
    }

    componentDidMount() {
        this.generateChart()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data) {
            this.generateChart()
        }
    }

    pack = data => d3.pack()
        .size([this.chart.getBoundingClientRect().width, this.chart.getBoundingClientRect().height])
        .padding(3)
        (d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value))

    findDataWithName = (name, data) => {
        let r = null
        const children = data.children || []
        //Find in children
        r = children.find(d => d.data.name === name)

        //Search each child recursively
        let i = 0
        while(!r && i < children.length) {
            r = this.findDataWithName(name, children[i])
            i += 1
        }
        return r
    }

    generateChart = () => {
        const { width, color, pack } = this
        const { data, selectedAttribute } = this.props
        if(!data){
            return null
        }
        const root = pack(data);
        let focus = null
        let view;
        if(this.state.focusName) {
            focus = this.findDataWithName(this.state.focusName, root) || root
        }
        // const boxCoordinates = this.chart.getBoundingClientRect()
        d3.select(this.chart).selectAll('g').remove()
        const svg = d3.select(this.chart)
            .attr("viewBox", '-500 -400 1000 800')
            .style("display", "block")
            .style("background", color(0))
            .style("cursor", "pointer")
            .style("height", "80vh")
            .style("width", "100%")

        if (!data.children?.length || !data.hasData) {
            svg.append("g")
              .attr("x", '50%')
              .attr("y", "50%")
              .append("text")
              .attr("text-anchor", "middle")
              .text(`No ${!data.children?.length ? 'Data for' : ''} ${selectedAttribute?.name}`)
            
              return
        }

        svg.on("click", () => zoom(root));

        const node = svg.append("g")
            .selectAll("circle")
            .data(root.descendants().slice(1))
            .join("circle")
            .attr("fill", d => d.children ? color(d.depth) : "white")
            .attr("pointer-events", d => !d.children ? "none" : null)
            .on("mouseover", function () { d3.select(this).attr("stroke", "#000"); })
            .on("mouseout", function () { d3.select(this).attr("stroke", null); })
            .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()));

        const label = svg.append("g")
            .style("font", "14px sans-serif")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .selectAll("text")
            .data(root.descendants())
            .join("text")
            .style("fill-opacity", d => d.parent === root ? 1 : 0)
            .style("display", d => d.parent === root ? "inline" : "none")
            .text(d => {
                if (d.data.name.includes('\n')) {
                    return d.data.name.split('\n')[0]
                }
                return d.data.name
            });

        const label2 = svg.append("g")
            .style("font", "14px sans-serif")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .selectAll("text")
            .data(root.descendants())
            .join("text")
            .style("fill-opacity", d => d.parent === root ? 1 : 0)
            .style("display", d => d.parent === root ? "inline" : "none")
            .text(d => {
                if (d.data.name.includes('\n')) {
                    return d.data.name.split('\n')[1]
                }
                return null
            });


        const zoomTo = (v) => {
            const k = width / v[2];

            view = v;

            label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
            label2.attr("transform", d => `translate(${(d.x - v[0]) * k},${((d.y - v[1]) * k) + 20})`);
            node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
            node.attr("r", d => d.r * k);
        }


        const zoom = (d) => {
            // const focus0 = focus;

            focus = d;
            this.setState({focusName: d.data.name})

            const transition = svg.transition()
                .duration((d3.event && d3.event.altKey) ? 7500 : 750)
                .tween("zoom", d => {
                    const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                    return t => zoomTo(i(t));
                });

            label
                .filter(function (d) { return d.parent === focus || this.style.display === "inline"; })
                .transition(transition)
                .style("fill-opacity", d => d.parent === focus ? 1 : 0)
                .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
                .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });

            label2
                .filter(function (d) { return d.parent === focus || this.style.display === "inline"; })
                .transition(transition)
                .style("fill-opacity", d => d.parent === focus ? 1 : 0)
                .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
                .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });

        }

        if(focus) {
            zoomTo([focus.x, focus.y, focus.r * 2]);
            zoom(focus)
        } else {
            focus = root
            zoomTo([root.x, root.y, root.r * 2]);
        }
    }

    render() {
        return (
            <svg ref={node => this.chart = node} />
        )
    }
}

export default BubbleChart