import React, { useState } from 'react'

import Button from '@material-ui/core/Button'

import { observer } from 'mobx-react-lite'

import { collaborationDataService, dataService } from '../../services'
import { strategyStore } from '../../stores'
import { List, ListItem, ListItemText, ListItemIcon, TextField } from '@material-ui/core'

import { duration } from 'moment'
import CollaborationStatusIcon from './CollaborationStatusIcon'

const CollaborationPanel = observer(() => {
  const [dataSetName, setDataSetName] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const { collaborationData } = strategyStore

  const handleNameChange = (e) => {
    setDataSetName(e.target.value)
  }

  const handleEnableCollabClick = async () => {
    console.log("Enabling collaboration!")
    const repo = dataService.getRepo()
    try {
      setErrorMessage(null)
      await collaborationDataService.enableCollaboration(dataSetName, repo)
    } catch (err) {
      console.error("Failed to enable collaboration for data set: ", err)
      setErrorMessage(err.message || "Failed!")
    }
  }

  if(collaborationData.status !== 'Online') {
    return (
      <div>
        <h2>Collaboration Unavailable</h2>
        <List>
          <ListItem>
            <ListItemText color='error' primary={collaborationData.status} secondary={collaborationData.failure} />
          </ListItem>
        </List>
      </div>
    )
  }

  return (
    <div>
      {/* <div> {JSON.stringify(collaborationData)} </div> */}
      {/* <hr/> */}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {!strategyStore.dataSetName &&
        <div>
          <TextField 
            label="Dataset Name" 
            required 
            autoFocus 
            error={dataSetName && collaborationData.tables.indexOf(`BASS_Data_${dataSetName}`) >= 0}
            onChange={handleNameChange} 
          />
          <Button 
            variant="contained" 
            disabled={!dataSetName}
            onClick={handleEnableCollabClick} 
          >Enable Collaboration</Button>
        </div>
      }
      {strategyStore.dataSetName &&
        <div>
          <h3>Collaboration Enabled</h3>
          <div>
            {/* <div>{JSON.stringify(collaborationData)}</div> */}
            <List>
              <ListItem>
                <ListItemIcon>
                  <CollaborationStatusIcon />
                </ListItemIcon>
                <ListItemText primary="Data Set" secondary={strategyStore.dataSetName} />
                <ListItemText primary="Last Server Status" secondary={collaborationData.lastServerStatus} />
                <ListItemText primary="Last Server Check" secondary={duration(collaborationData.lastServerCheck - Date.now()).humanize()} />
                {collaborationData.lastServerError &&
                <ListItemText primary="Last Server Error" secondary={collaborationData.lastServerError} />
                }
              </ListItem>
            </List>
          </div>
        </div>
      }
    </div>
  )
})

export default CollaborationPanel