import React from 'react'
import { observer } from 'mobx-react-lite'
import {
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import ResourceTree from '../ResourceTree/ResourceTree'
import VisualizationContainer from '../VisualizationContainer'

import './MainContainer.css'
import SystemContainer from '../SystemContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { featureStore } from '../../stores'

const MainContainer = observer(({ searchResult, resetTime, isOpen }) => {
    const { features: { enableStrategyUI } } = featureStore

    return (
        <div id='main-container'>
            <Switch>
                <Route path="/strategy">
                {enableStrategyUI ?
                        <div style={{ color: 'white' }}>New Strategy UI Coming Soon!</div>
                        :
                        <DndProvider backend={HTML5Backend}>
                            <ResourceTree resetTime={resetTime} searchResult={searchResult} isOpen={isOpen} />
                        </DndProvider>
                    }
                </Route>
                <Route path={["/business","/alignment"]}>
                    <VisualizationContainer />
                </Route>
                <Route path="/system">
                    <SystemContainer isOpen ={isOpen} />
                </Route>
                <Route path="/">
                    <Redirect to="/strategy" />
                </Route>
            </Switch>
        </div>
    )
})

export default MainContainer