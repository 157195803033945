import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { ClickAwayListener, Slider } from '@material-ui/core'
import moment from 'moment'
import { ChevronLeft, ChevronRight, Pause, PlayArrow } from '@material-ui/icons'
import './TimeControl.css'

function TimeControl({ sliderDates, sliderDate, setSliderDate, location }) {

  const [playback, setPlayback] = useState(false)

  const playbackTimeout = useRef()
  const sliderRef = useRef()

  const startPlayback = () => {
    setPlayback(true)
  }

  const stopPlayback = () => {
    clearTimeout(playbackTimeout.current)
    setPlayback(false)
  }

  // playback
  useEffect(() => {
    if (playback && sliderDates.length > 1 && sliderDates.indexOf(sliderDate) < sliderDates.length - 1) {
      playbackTimeout.current = setTimeout(() => {
        setSliderDate(sliderDates[sliderDates.indexOf(sliderDate) + 1])
      }, 750)
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playback, sliderDate])

  useEffect(() => {
    const spacebarListener = (e) => {
      if (e.key === " ") {
        playback ? stopPlayback() : startPlayback()
      }
    }
    document.addEventListener('keydown', spacebarListener)
    return () => document.removeEventListener('keydown', spacebarListener)
  })

  useEffect(() => {
    const e = document.getElementsByClassName('MuiSlider-thumb')[0]
    if(e) {
      e.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderDates, sliderRef.current, location.pathname])

  return (
    <div className='vis-slider-container'>
      <div className='vis-slider-controls-container'>
        <button
          disabled={sliderDates.indexOf(sliderDate) <= 0}
          onClick={() => setSliderDate(sliderDates[sliderDates.indexOf(sliderDate) - 1])}
        ><ChevronLeft /></button>
        <ClickAwayListener
          mouseEvent='onMouseDown'
          onClickAway={() => stopPlayback()}
        >
          <button
            style={{ width: '60px' }}
            disabled={sliderDates.indexOf(sliderDate) >= sliderDates.length - 1}
            onClick={() => playback ? stopPlayback() : startPlayback()}
          >{playback ? <Pause /> : <PlayArrow />}</button>
        </ClickAwayListener>
        <button
          disabled={sliderDates.indexOf(sliderDate) >= sliderDates.length - 1}
          onClick={() => setSliderDate(sliderDates[sliderDates.indexOf(sliderDate) + 1])}
        ><ChevronRight /></button>
      </div>
      <Slider
        ref={sliderRef}
        className="vis-date-slider"
        defaultValue={sliderDates[sliderDates.length - 1]}
        valueLabelFormat={(value) => <div>{moment(value).format('M/D')}</div>}
        aria-labelledby="discrete-slider-custom"
        min={sliderDates[0]}
        max={sliderDates[sliderDates.length - 1]}
        step={null}
        value={sliderDate}
        valueLabelDisplay="on"
        marks={sliderDates.map((ts) => ({ value: Number(ts) }))}
        onChange={(ev, value) => {
          setSliderDate(value)
        }
      }
      />
    </div>
  )
}

export default withRouter(TimeControl)