import React from 'react'
import { useDrag } from 'react-dnd'

import './ContentDisplay.css'

function TierTag({ tag, title, itemId, type, parentId }) {
  const [{isDragging}, drag] = useDrag({
    item: { id: itemId, type: type, parentId },
    collect: (monitor) => ({isDragging: !!monitor.isDragging()})
  })

  return (
    <div ref={drag} className={`content-display-tag${isDragging ? " dragging" : ""}`} title={title}>
      {tag}
    </div>
  )
}

export default TierTag