import React from 'react'
import { Avatar } from '@material-ui/core'

function UserBadge ({ name, role, userImage }) {

  return (
    <div style={{ display: 'flex' }}>
      <Avatar alt={name} src={userImage} id="user-avatar" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '15px' }}>
        <span style={{ color: "#FFF", fontSize: "18px", fontWeight: "500" }}>{name}</span>
        <span style={{ color: "#FFF", fontSize: "18px", fontWeight: "300" }}>{role}</span>
      </div>
    </div>
  )
}

export default UserBadge