import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { differenceWith, isEqual, valuesIn, compact, cloneDeep, keyBy } from 'lodash'
import { Delete } from '@material-ui/icons'
import EditableTable from './EditableTable'
import dataService from '../../../services/dataService'

import { strategyStore } from '../../../stores'
import { observer } from 'mobx-react-lite'

const EditableTableContainer = observer(({configColumns=[], resourcePath}) => {
  const [tableData, setTableData] = useState(null)
  const [originalData, setOriginalData] = useState(null)
  const [changedRows, setChangedRows] = useState([])
  const [allData, setAllData] = useState({})
  const [editableRows, setEditableRows] = useState([])

  const fetchData = useCallback(() => {
    const fetchData = (() => {
      setTableData(strategyStore[resourcePath])
      setOriginalData(strategyStore[resourcePath])
      const keyedData = {}
      const allData = dataService.getDataFromStores()
      for (let dataType in allData) {
        keyedData[dataType] = keyBy(allData[dataType], 'id')
      }
      setAllData(keyedData)
    })
    fetchData()
  }, [resourcePath])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const addRow = useCallback(() => {
    setEditableRows(oldEditableRows => {
      return [...oldEditableRows, tableData.length.toString()]
    })
    setTableData((oldTableData) => {
      if (oldTableData) {
        return [...oldTableData, {}]
      }
      return [{}]
    })
  }, [tableData])

  const removeRow = useCallback((rowIndex) => {
    setEditableRows(oldEditableRows => {
      // eslint-disable-next-line
      return oldEditableRows.filter(row => row != rowIndex)
    })
    setTableData(oldTableData => {
      if (oldTableData) {
        const newTableData = cloneDeep(oldTableData)
        newTableData[rowIndex].deleted = !newTableData[rowIndex].deleted
        return newTableData
      }
      return oldTableData
    })
  }, [])

  useEffect(() => {
    if (tableData && originalData) {
      setChangedRows(differenceWith(tableData, originalData, (val1, val2) => {
        const { deleted: d1, ...val1Data } = val1
        const { deleted: d2, ...val2Data } = val2
        return isEqual(val1Data, val2Data)
      }))
    }
  }, [tableData, originalData])

  useEffect(() => {
    setTableData(oldTableData => {
      const newTableData = cloneDeep(oldTableData)
      editableRows.forEach(row => {
        if (newTableData[row].deleted) {
          newTableData[row].deleted = false
        }
      })

      return newTableData
    })
  }, [editableRows])

  const saveHandler = async () => {
    const saveResource = (async (resource) => {
      delete resource.delete
      if (resource.id) {
        return dataService.put(`/${resourcePath}/${resource.id}`, resource)
      } else {
        return dataService.post(`/${resourcePath}`, resource)
      }
    })
    const deleteResource = (async (resource) => {
      if (resource.id) {
        return dataService.delete(`/${resourcePath}/${resource.id}`)
      }
    })
    const deletedRows = tableData.filter(row => row.deleted)
    const savePromises = changedRows
      .filter(row => compact(valuesIn(row)).length)
      .map(saveResource)
      .concat(deletedRows.map(deleteResource))

    await Promise.all(savePromises)
    fetchData()
    setEditableRows([])
  }

  const updateTableData = useCallback((rowIndex, property, newValue) => {
    setTableData(old => {
      return old.map((resource, index) => {
        // eslint-disable-next-line
        if (rowIndex == index) {
          return {
            ...resource,
            [property]: newValue,
          }
        }
        return resource
      })
    })
  }, [])

  const revertHandler = useCallback( ()=> {
    setTableData(originalData)
    setEditableRows([])
  }, [originalData])

  const tableColumns = useMemo(() => {
    return [
      {
        accessor: 'delete',
        Header: '',
        width: 15,
        disableFilters: true,
        disableSortBy: true,
        disableGroupBy: true,
        Cell: ({ row: { index } }) => {
          return (
          <div onClick={(e) => {
            e.stopPropagation()
            removeRow(index)
          }}>
            <Delete />
          </div>
          )
        }
      },
      // {
      //   accessor: 'id',
      //   Header: 'ID',
      //   Cell: ({ value }) => {
      //     return value || "*NEW*"
      //   }
      // },
      ...configColumns.map(column => {
        const newColumn = cloneDeep(column)
        const originalCell = column.Cell
        if (originalCell) {
          newColumn.Cell = (...cellArgs) => {
            const [firstArg, ...otherArgs] = cellArgs
            return originalCell({...firstArg, updateTableData, allData, editableRows}, ...otherArgs)
          }
        }
        return newColumn
      })
    ]
  }, [removeRow, updateTableData, configColumns, allData, editableRows])

  
  return (
    !tableData ? null :
    <EditableTable
      data={tableData}
      allData={allData}
      columns={tableColumns}
      revertHandler={revertHandler}
      addRow={addRow}
      saveHandler={saveHandler}
      setEditableRows={setEditableRows}
      editableRows={editableRows}
    />
  )
})

export default EditableTableContainer