import React, { useCallback, useMemo, Fragment } from 'react'
import { useTable, useFlexLayout, useSortBy, useFilters, useExpanded } from 'react-table'
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Button } from '@material-ui/core'

import { filterTypes, defaultTableFilterOptions, defaultColumnFilterOptions } from './useFilter'
import { sortTypes, defaultTableSortByOptions, defaultColumnSortByOptions } from './useSortBy'
import { aggregateTypes, defaultTableGroupByOptions, defaultColumnGroupByOptions } from './useGroupBy'
import './EditableTable.css'

function EditableTable({ columns, data, allData, addRow, saveHandler, revertHandler, editableRows, setEditableRows, tableOptions }) {
  const initialState = {
    // filters: [{ id: '_accessor', value: 'filter value (any)'}]
    sortBy: [{ id: 'dateTime', desc: true }, { id: 'recordedDate', desc: true }]
    // groupBy: ['id/accessor', 'id/accessor',...]
  }

  const idColumnSort = useCallback((rowA, rowB, columnId, desc) => {
    const columnType = columnId.slice(0, -2)
    const dataType = columnType === 'strategy' ? 'strategies' : `${columnType}s`
    const data = allData[dataType]
    const rowAItem = data[rowA?.original?.[columnId]]
    const rowBItem = data[rowB?.original?.[columnId]]

    const rowAVal = rowAItem?.name || rowAItem?.title || Infinity
    const rowBVal = rowBItem?.name || rowBItem?.title || Infinity

    return rowAVal > rowBVal ? 1 :
           rowAVal < rowBVal ? -1 :
           0
  }, [allData])

  const idColumnFilter = useCallback((rows, [columnId], filterValue) => {
    return rows.filter(row => {
      const isNew = !(row.original?.id || row.original?.existing)
      if (isNew) {
        return true
      }

      const columnType = columnId.slice(0, -2)
      const dataType = columnType === 'strategy' ? 'strategies' : `${columnType}s`
      const rowItem = allData[dataType]?.[row.values[columnId]]
      const columnValue = rowItem?.name || rowItem?.title

      return columnValue
        ? String(columnValue)
            .toLowerCase()
            .indexOf(String(filterValue).toLowerCase()) > -1
        : false
    })
  }, [allData])
  

  const {
    filterTypes: propFilterTypes,
    sortTypes: propSortTypes,
    aggregations: propAggregateTypes,
    ...primitiveTableOptions
  } = tableOptions || {}

  const tableOptionsMemo = useMemo(() =>
    ({
      ...defaultTableFilterOptions,
      ...defaultTableSortByOptions,
      ...defaultTableGroupByOptions,
      filterTypes: { ...filterTypes, idColumnFilter, ...propFilterTypes },
      sortTypes: { ...sortTypes, idColumnSort, ...propSortTypes },
      aggregations: { ...aggregateTypes, ...propAggregateTypes },
      ...primitiveTableOptions,
      initialState
    }),
    [
      idColumnSort,
      idColumnFilter,
      initialState,
      primitiveTableOptions,
      propAggregateTypes,
      propFilterTypes,
      propSortTypes
    ]
  )

  const defaultColumn = useMemo(
    () => ({
      ...defaultColumnFilterOptions,
      ...defaultColumnSortByOptions,
      ...defaultColumnGroupByOptions,
      minWidth: 30,
      width: 150,
      maxWidth: 200,
      Footer: undefined,
    }),
    []
  )

  const emptyFirst = useCallback((rowA, rowB) => {
    const newA = !(rowA.original.id || rowA.original.existing)
    const newB = !(rowB.original.id || rowB.original.existing)

    if (newA && !newB) {
      return -1
    }
  
    if (!newA && newB) {
      return 1
    }
  
    return 0
  }, [])

  
  const { 
    getTableProps, 
    headerGroups, 
    rows, 
    prepareRow,
    visibleColumns
  } = useTable({
      columns,
      data,
      defaultColumn,
      ...tableOptionsMemo
    },
    useFilters,
    useSortBy,
    useExpanded,
    useFlexLayout
  )

  return (
    <>
      <div className="editable-table-button-container">
        <Button
          variant='outlined'
          onClick={addRow}
        >
          Add
        </Button>
        <Button
          variant='outlined'
          onClick={saveHandler}
          id='save-data'
        >
          Save
        </Button>
        <Button
          variant='outlined'
          onClick={revertHandler}
        >
          Revert
        </Button>
      </div>
      <TableContainer className='editable-table-container'>
        <Table className='editable-table' {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map(headerGroup => {
              const headers = headerGroup.headers.filter(column => column.Header !== "DESCRIPTION")
              const { key: headerKey, ...otherProps } = headerGroup.getHeaderGroupProps()
              return (
                <Fragment key={headerKey}>
                  <TableRow {...otherProps}>
                    {headers.map(column => {
                      const groupByToggleProps = (column.getGroupByToggleProps && column.getGroupByToggleProps()) || {}
                      return (
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                            {column.render('Header')}
                            {
                              column.isSorted
                              ? column.isSortedDesc
                                ? <>&nbsp;🔽</>
                                : <>&nbsp;🔼</>
                              : ''
                            }
                            { groupByToggleProps.onClick &&
                              <span {...groupByToggleProps}>
                                {column.isGrouped ? 'GroupedBy' : 'GroupBy'}
                              </span>
                            }
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow {...otherProps}>
                    {headers.map(column => (
                      <TableCell {...column.getHeaderProps()} >
                        <div
                          className={`th-filter`}
                        >
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              )
            })}
          </TableHead>
          <TableBody>
            {rows.sort(emptyFirst).map(row => {
              prepareRow(row)
              const descriptionCell = row.cells.find(cell => cell.column.Header === "DESCRIPTION")
              const currentlyEditing = editableRows.indexOf(row.id) >= 0
              return (
                <Fragment key={row.id}>
                  <TableRow
                    {...row.getRowProps()}
                    className={ row?.original?.deleted ? 'deleted-row' : ''}
                    onClick={(data) => {
                      if(!row.original || currentlyEditing) {
                        return
                      }
                      setEditableRows([...editableRows, row.id])
                    }}
                  >
                    {row.cells.map(cell => {
                      if (cell.column.Header === "DESCRIPTION") {
                        return null
                      }

                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.isGrouped ? (
                              // If it's a grouped cell, add an expander and row count
                              <>
                                <span {...row.getToggleRowExpandedProps()}>
                                  {row.isExpanded ? '👇' : '👉'}
                                </span>{' '}
                                {cell.render('Cell', { editable: false })} (
                                {row.subRows.length})
                              </>
                            ) : cell.isAggregated ? (
                              // If the cell is aggregated, use the Aggregated
                              // renderer for cell
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                              // Otherwise, just render the regular cell
                              cell.render('Cell', { editable: true })
                            )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {currentlyEditing && descriptionCell ? (
                    <TableRow>
                      <TableCell colSpan={visibleColumns.length}>
                        {descriptionCell.render('Cell')}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default EditableTable